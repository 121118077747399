import styled from "styled-components";

export const Wrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
position: relative;
`;

export const CoverArt = styled.div`
display: flex;
height: 400px;
overflow: hidden;
position: relative;
justify-content: flex-end;
align-items: center;
width: 100%;
img{
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}
`;

export const CollectionInfoBgd = styled.div`
position: absolute;
height: 100%;
width: 30%;
padding: 50px;
display: flex;
min-width: 400px;
align-items: center;
`;

export const CollectionInfo = styled.div`
background: rgba(0,0,0,0.85);
color: #ffffff;
display: flex;
user-select: none;
flex-direction: column;
height: 100%;
text-align: center;
width: 100%;
padding: 20px;
justify-content: center;
align-items: center;
`;

export const Title = styled.div`

`;


export const Description = styled.div`
font-size: 16px;
margin: 20px 0;
`;

export const SubTitle = styled.div`
font-size: 1.5rem;
font-weight: 700;
user-select: none;
`;

export const Artist = styled.div`
font-size: 2rem;
`;

export const ArtworksContainer = styled.div`
display: flex;
justify-content: center;
width: 100%;
flex-wrap: wrap;
position: relative;
`;