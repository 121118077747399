import styled from 'styled-components';

export const DropdownListContainer = styled.div`
    
`;

export const DropdownContainer = styled.div`
    height: 100%;
    width: ${props => props.width ? props.width : '100%'};
    position: relative;
    background: ${props => props.background ? props.background : 'var(--grey)'};
    text-transform: uppercase;
    text-align: ${props => props.textAlign ? props.textAlign : 'left'};
    border: ${props => props.border ? props.border : ''};
font-size: 10px;
font-weight: 500;
letter-spacing: 0.1rem;
position: relative;
    
`;

export const DropdownHeader = styled.div`
    height: ${props => props.height ? props.height : '1rem'};
    background: ${props => props.background ? props.background : 'var(--grey)'};
    display: flex;
    align-items: center;
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'flex-end'};
    margin-right: ${props => props.marginRight ? props.marginRight : ''};
    padding: ${props => props.paddingRight ? `0 ${props.paddingRight}` : ''};
    user-select: none;
    /* img{
        width: 12px;
        position: absolute;
        right: 20px;
    } */
    cursor: pointer;
`;

export const DropdownList = styled.ul`
margin: 0;
margin-top: ${props => props.marginTop ? props.marginTop : ''};
padding: 0;
background: ${props => props.listBackground ? props.listBackground : 'var(--grey)'};
position: absolute;
border: ${props => props.border ? props.border : ''};
width: 100%;
z-index: 2;
box-shadow: 0 5px 3px -1px rgb(0,0,0);
height: min-content;

`;

export const ListItem = styled.li`
    height: 40px;
    list-style: none;
    display: flex;
    position: relative;
    align-items: center;
    margin: 0;
    padding: 0 20px;
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'flex-end'};
    user-select: none;
    cursor: pointer;
    background: ${props => props.isSelected ? 'rgba(255,255,255,0.2)' : 'transparent'};
    color: ${props => props.isSelected ? props.colorOnSelect ? props.colorOnSelect : 'rgb(255,255,255)' : props.color ? props.color : 'var(--purpleGrey)'};
    :hover{
        background: rgba(255,255,255,0.2);
    }
`;

export const DropdownArrow = styled.img`
    transform: ${props => props.shouldRotate ? 'rotate(180deg)' : '0'};
`;

export const LabelStyled = styled.label`
    position: relative;
    font-size:  1em;
    display:inline-block;
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0'};

`;

export const SortWrapper = styled.div`
height: 20px;
position: absolute;
right: 0;

display: flex;
align-items: center;
justify-content: center;
img{
    position: relative;
    height: 13px;
    width: 13px;
}
`;