import styled from "styled-components";

export const Wrapper = styled.div`
/* border: var(--defOutline); */
height: 100%;
width: 100%;
margin-bottom: 20px;
display: grid;
grid-template-columns: 1fr 1fr;
grid-template-rows: min-content 1fr;
grid-column-gap: 20px;
grid-row-gap: 20px;
`;

export const ActiveArtistSection = styled.div`
background: var(--lightestGrey);
display: grid;
grid-template-rows: min-content min-content min-content 1fr;
`;

export const AddArtistWrapper = styled.div`
cursor: pointer;
width: 30px;
height: 30px;
padding: 5px;

`;

export const ArtistWrapper = styled.div`
border: var(--defOutline);
background: var(--background);
display: grid;
grid-template-columns: min-content 1fr min-content;
align-items: center;
margin: 15px 0;
border-radius: 30px;
padding: 5px;

`;


export const ProfilePicture = styled.div`
width: 25px;
height: 25px;
border-radius: 50%;
display: flex;
overflow: hidden;
img{
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}
`;

export const ArtistName = styled.div`
`;

export const ScrollWrapper = styled.div`
overflow: auto;
max-height: 100%;
border: 1px solid var(--lightestGrey);
position: relative;
font-size: 14px;
`;

export const ScrollContent = styled.div`
height: 100%;
scroll-behavior: smooth;
display: grid;
grid-row-gap: 10px;
white-space: nowrap;
grid-template-columns: min-content 1fr min-content min-content min-content min-content;
grid-auto-rows: min-content;
margin-left: 10px;
grid-column-gap: 20px;
overflow-y: ${props=> props.isCurrent ? '' : 'scroll'};
align-items: center;

`;

export const TableHeader = styled.div`
text-transform: uppercase;
font-size: 10px;
visibility: ${props => props.visibility ? props.visibility : 'visible'};
margin-bottom: 10px;
text-align: ${props=> props.align ? props.align : 'left'};
`;

export const Header = styled.div`
scroll-behavior: smooth;
display: grid;
grid-row-gap: 10px;
white-space: nowrap;
grid-template-columns: 25px 1fr min-content min-content min-content min-content;
grid-auto-flow: rows;
grid-column-gap: 20px;
margin-right:  ${props=> props.isCurrent ? '0' : '17px'};
margin-left: 10px;

`;

export const GridItem = styled.div`
grid-column: ${props => props.gridCol ? props.gridCol : 'auto'};
text-align: ${props=> props.align ? props.align : 'left'};
color: ${props=> props.isSelected ? 'var(--uiollyPurple)' : 'var(--fontColor)'};
`;