export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const returnMonth = (date, abbreviated = false) => {

    const month = date.includes('/') ? date.split('/')[0] : date.split('-')[1];
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const monthsAbbreviated = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
    var month_index = month - 1;
    return abbreviated ? monthsAbbreviated[month_index] : months[month_index];
}

export const artworkPath = (path) => {
    const artworkPath = path.split('./').length > 1 ? path.split('./')[1] : path.split('./')[0];
    return `https://426xz11dbtest123.testdomain415.site/nftapi/assets/${artworkPath}`;
}

export const formattedDateAndTime = (date) => {
    const time = date.split(' ')[1].substr(0, date.split(' ')[1].lastIndexOf(':'))
    const t = parseInt(time.split(':')[0]) >= 12 ? `${parseInt(time.split(':')[0]) - 12}:${time.split(':')[1]}pm` : `${parseInt(time.split(':')[0])}:${time.split(':')[1]}am`;
    return returnMonth(date, true) + ' ' + date.split('-')[2].split(' ')[0] + ' ' + t;
}

export const getFileType = (name = "") => {
    const fileExtension = name.split('.').pop()
    return ["image/jpeg", "image/jpg", "image/png", "audio/mpeg", "audio/wav", "video/mpeg", "video/mp4"].filter(type => type.includes(fileExtension.toLowerCase()))[0].includes("video") ? "video" : "image"
}

export const removeTrailingZeroes = (number) => {
    return number.replace(/^0+(\d)|(\d)0+$/gm, '$1$2')
    
}