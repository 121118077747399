import styled from "styled-components";

export const CoverArt = styled.div`
position: relative;
background-image: ${props => props.backgroundIMG ? `url(${props.backgroundIMG})` : '#ffb375'};
overflow: hidden;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border: 1px solid #000000;

height: 25px;
width: 25px;
border-radius: 50%;
border-radius: 5px;
`;
export const EventBubble = styled.div`
    /* background-image: ${props => props.backgroundIMG ? `url(${props.backgroundIMG})` : '#ffb375'}; */
    background: #ffb375;
    border: 1px solid #000000;
    background-position: center;
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
    border-radius: 5px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    text-transform: uppercase;
    padding: 5px;
    user-select: none;
    font-size: 10px;
    margin-top: 5px;
    /* transform: ${props => !props.isSelected ? 'scale(0.85)' : 'undefined' }; */
    opacity: ${props => props.isSelected ? 1 : 0.35 };
`;

export const Wrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
`;

export const ReleaseDate = styled.div`
font-size: 12px;
`;