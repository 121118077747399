import React, { useState, useEffect } from 'react';
import Modal from '../../../Modal'
import Checkbox from '../../../Checkbox'
import {
    ScrollWrapper,
    Label,
    Row,
    ScrollContent,
    Bid,
    Header,
    MinBid,
    Wrapper,
    GridHeader,
    ArtistWrapper,
    ProfilePicture,
    ActiveArtistSection,
    TableHeader,
    AddArtistWrapper,
    GridItem
} from './ProcessArtistModal.styles'


const ProcessArtistModal = ({ selectedArtists, setSelectedArtists, activeArtists, onClose, setShowModal, showModal, setSelArtist, selectedArtist, handleChange, type, loading }) => {
    const { artist, action } = selectedArtist;

    return (
        <Modal
            onClick={() => { handleChange(artist, type) }}
            disabled={loading}
            onClose={onClose}
            buttonText={type}
            showModal={showModal}
            title={'Edit Featured Artists'}
            subtextSize="14px"
            subtext="You can feature up to 3 artists on the landing page."
            closeModal={() => setShowModal(false)}>
            {/* <Header isHeader>
                ProcessArtistModal
            </Header> */}
            <div>
                Add {artist.artist_name} to featured artists?
            </div>
            <ActiveArtistSection>
                <h4>All Active Artists</h4>
                <p>Artists with collections pending, live, or sold.</p>
                <GridHeader>
                    <TableHeader></TableHeader>
                    <TableHeader>Name</TableHeader>
                    <TableHeader>Live Collections</TableHeader>
                    <TableHeader>Past Collections</TableHeader>
                    <TableHeader>Future Collections</TableHeader>
                    <TableHeader>Add</TableHeader>
                </GridHeader>
                <ScrollWrapper>
                    <ScrollContent>
                        <TableHeader visibility="hidden"></TableHeader>
                        <TableHeader visibility="hidden">Name</TableHeader>
                        <TableHeader visibility="hidden">Live Collections</TableHeader>
                        <TableHeader visibility="hidden">Past Collections</TableHeader>
                        <TableHeader visibility="hidden">Future Collections</TableHeader>
                        <TableHeader visibility="hidden">Add</TableHeader>
                        {
                            activeArtists.map(artist => (
                                // <ArtistWrapper key={artist.id}>
                                <>
                                    <ProfilePicture><img src={artist.profile_pic} /></ProfilePicture>
                                    <GridItem isSelected={selectedArtist.artist.artist_id === artist.artist_id}>{artist.artist_name}</GridItem>
                                    <GridItem isSelected={selectedArtist.artist.artist_id === artist.artist_id} align="center">{artist.num_c_live}</GridItem>
                                    <GridItem isSelected={selectedArtist.artist.artist_id === artist.artist_id} align="center">{artist.num_c_past}</GridItem>
                                    <GridItem isSelected={selectedArtist.artist.artist_id === artist.artist_id} align="center">{artist.num_c_future}</GridItem>
                                    <Checkbox
                                        onCheck={() => {
                                            setSelectedArtists(prev => ({ ...prev, addArtists: [...prev.addArtists, artist] }))
                                            console.log("selected artists", selectedArtists)
                                        }}
                                    />
                                </>
                                // </ArtistWrapper>
                            ))
                        }
                    </ScrollContent>
                </ScrollWrapper>
            </ActiveArtistSection>
        </Modal>
    )
};

export default ProcessArtistModal;
