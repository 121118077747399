import styled from "styled-components";

export const Overlay = styled.div`
    display: flex;
`;

export const ModalOverlay = styled.div`
position: absolute;
display: flex;
justify-content: center;
align-items: center;
overflow: hidden;
width: ${props => props.modalVisible ? '100%' : '0'};
height: ${props => props.modalVisible ? '100vh' : '0'};
left: 0;
top: 0;
/* background: var(--overlayBgd); */
z-index: 2;
visibility: ${props => props.modalVisible ? 'visible' : 'hidden'};
`;

export const CloseButtonContainer = styled.div`
display: flex;
width: 100%;
justify-content: flex-end;
position: relative;
grid-area: close;
img{
height: 20px;
width: 20px;
cursor: pointer;

}
`;

export const Modal = styled.div`
width: 400px;
display: grid;
font-size: 14px;
white-space: nowrap;
text-transform: uppercase;
grid-template-rows: repeat(2,min-content);
grid-template-columns: min-content 1fr;
grid-column-gap: 1rem;
grid-row-gap: 10px;
grid-template-areas:
    'title close'
    'content content';
z-index: 1;
visibility: ${props => props.modalVisible ? 'visible' : 'hidden'};
text-align: center;
padding: 20px;
user-select: none;
background: var(--background);
box-shadow: 0 0 20px 2px rgba(0,0,0,0.3);
`;

export const ModalContent = styled.div`
display: grid;
grid-template-rows: repeat(5,min-content);
grid-template-columns: min-content 1fr;
grid-area: content;
grid-column-gap: 1rem;
grid-row-gap: 10px;
grid-template-areas:
    'labelDate date'
    'labelStartTime startTime'
    'labelRunTime runTime'
    'labelCollection collection'
    'button button';
`;

export const ButtonWrapper = styled.div`
grid-area: button;
width: 100%;
`;

export const Title = styled.div`
font-size: 18px;
grid-area: title;
width: 100%;
text-align: left;
text-transform: uppercase;
`;

export const DateWrapper = styled.div`

width: 100%;
text-align: left;
text-transform: uppercase;
`;

export const TimeWrapper = styled.div`
display: flex;
align-items: center;
grid-area: startTime;
justify-content: ${props => props.justifyContent ? props.justifyContent : 'space-between'};
`;

export const Tile = styled.div`
max-width: 100%;
grid-area: collection;
`;

export const TimeInput = styled.input`
height: 48px;
text-align: left;
width: 6rem;
margin-right: 10px;
text-align: right;
`;

export const InputField = styled(TimeInput)`
width: 3rem;
position: relative;
`;

export const TimeZone = styled.div`

`;

export const Label = styled.div`
text-align: right;
display: flex;
align-items: center;
flex-direction: column;
justify-content: flex-end;
grid-area: ${props => props.gridArea ? props.gridArea : 'auto'};
`;

export const SmallText = styled.div`
display: flex;
text-transform: none;
font-size: 10px;
width: 100%;
justify-content: flex-end;
margin-right: 10px;
`;

export const GridItem = styled.div`
display: grid;
grid-template-columns: min-content 1fr;
grid-column-gap: 1rem;
justify-items: start;
grid-area: ${props => props.gridArea ? props.gridArea : 'auto'};
>div{
    display: flex;
    align-items: center;
}
`;
