import React, { useContext, useState, useEffect, useReducer } from 'react'
import dayjs from 'dayjs'
import API from '../API'
const CalendarContext = React.createContext()

export const useCalendar = () => {
    return useContext(CalendarContext)
}

export const CalendarProvider = ({ children }) => {
    const stickyValue = window.localStorage.getItem("selectedDay") !== null && window.localStorage.getItem("selectedDay") !== undefined ? dayjs(JSON.parse(window.localStorage.getItem("selectedDay"))) : dayjs().add(1, 'day');
    const [showEventModal, setShowEventModal] = useState(false);
    const closeEventModal = () => {
        setShowEventModal(false)
    }
    const savedEventsReducer = (state, { type, payload }) => {
        switch (type) {
            case 'push':
                return [...state, payload];
            case 'update':
                return state.map(evt => evt.id === payload.id ? payload : evt); //replace event with new state if an update occurs
            case 'delete':
                return state.filter(evt => evt.id !== payload.id);
            default:
                throw new Error();
        }
    }
    const [monthIndex, setMonthIndex] = useState(dayjs().month());
    const [scheduledCollections, setScheduledCollections] = useState([])
    const [monthUnixRange, setMonthUnixRange] = useState(new Array(2).fill(null))
    const [selectedDay, setSelectedDay] = useState(stickyValue);
    const [dateAndTime, setDateAndTime] = useState();
    const [selectedTime, setSelectedTime] = useState('');
    const [runTime, setRunTime] = useState({ hours: '', days: '', endDate: '' })
    const initEvents = () => {
        const storageEvents = localStorage.getItem('savedEvents')
        const parsedEvents = storageEvents ? JSON.parse(storageEvents) : []
        return parsedEvents
    }
    const [savedEvents, dispatchCalendarEvent] = useReducer(savedEventsReducer, [], initEvents)
    const getRangedCollections = async (unix_range) => {
        try {
            const f_c = await API.getScheduledCollectionsRanged(unix_range)
            //setScheduledCollections(f_c !== undefined ? f_c : [])
            if (f_c !== undefined && f_c.length > 0) {
                f_c.forEach(s_c => {
                    if (parseInt(s_c.status) === 4 || parseInt(s_c.status) === 3 && dayjs().unix() < parseInt(s_c.release_date !== null ? s_c.release_date : 0)) {
                        if (!scheduledCollections.some(c => c.collection_id === s_c.collection_id))
                            { setScheduledCollections(prev => ([...prev, s_c])) }
                    }
                    if (!savedEvents.some(evt => evt.collection_id === s_c.collection_id)) {
                        if ((parseInt(s_c.status) === 3 && dayjs().unix() > parseInt(s_c.release_date !== null ? s_c.release_date : 0)))
                            return
                        const calendarEvent = {
                            title: s_c.title,
                            artist: {
                                id: s_c.artist_id,
                                handle: s_c.handle
                            },
                            collection_id: s_c.collection_id,
                            cover_art: s_c.cover_path,//process.env.REACT_APP_BASE_PATH_ASSETS + s_c.cover_path.replace(/^\/+/g, ''),
                            description: 'descr',
                            dateAndTime: parseInt(s_c.release_date),
                            endDate: s_c.end_date,
                            status: s_c.status,
                            id: s_c.collection_id//Date.now()
                        }
                        dispatchCalendarEvent({ type: 'push', payload: calendarEvent })
                    }
                    else {

                        const evt = savedEvents.find(evt => evt.collection_id === s_c.collection_id)
                        if (parseInt(s_c.status) === 3 && dayjs().unix() >= parseInt(s_c.release_date !== null ? s_c.release_date : 0)) {
                            console.log("unminted", dayjs().unix() >= parseInt(s_c.release_date !== null ? s_c.release_date : 0))
                            const calendarEvent = {
                                id: s_c.collection_id//Date.now()
                            }
                            dispatchCalendarEvent({ type: 'delete', payload: calendarEvent })
                        }

                    }
                })

            }

        } catch (err) {
            console.log(err)
        }
    }


    useEffect(() => {
        localStorage.setItem('savedEvents', JSON.stringify(savedEvents))
    }, [savedEvents])

    useEffect(() => {
        if (selectedDay !== null)
            localStorage.setItem("selectedDay", JSON.stringify(selectedDay))
    }, [selectedDay])
    const value = {
        monthIndex,
        setMonthIndex,
        selectedDay,
        setSelectedDay,
        showEventModal,
        setShowEventModal,
        closeEventModal,
        savedEvents,
        dispatchCalendarEvent,
        setSelectedTime,
        selectedTime,
        setDateAndTime,
        setMonthUnixRange,
        monthUnixRange,
        setScheduledCollections,
        scheduledCollections,
        getRangedCollections,
        setRunTime,
        runTime
    }
    return (
        <CalendarContext.Provider value={value}>
            {children}
        </CalendarContext.Provider>
    )
}