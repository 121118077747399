import React, { useRef } from 'react';

import { CheckboxStyle } from './Checkbox.styles'

const Checkbox = ({ onCheck, text, isDisabled, isCheckedX }) => {
    // const isChecked = useRef(null)
    // return (
    //     <CheckboxStyle isDisabled={isDisabled}>{text} <input disabled={isDisabled && !(isChecked.current ? isChecked.current.checked : false)} ref={isChecked} type="checkbox" onChange={() => { onCheck(isChecked.current.checked) }}
    //     // checked={isChecked} 
    //     /><span></span></CheckboxStyle>
    // )

    return (
        <CheckboxStyle isDisabled={isDisabled}>{text} <input disabled={isDisabled && !isCheckedX} checked={isCheckedX} type="checkbox" onChange={() => { onCheck() }}
        // checked={isChecked} 
        /><span></span></CheckboxStyle>
    )
}

export default Checkbox;
