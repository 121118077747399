import logo from './logo.svg';
import './App.css';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';

import { AuthProvider } from './contexts/AuthContext'
import { CalendarProvider } from './contexts/CalendarContext'
import { CollectionsProvider } from './contexts/CollectionsContext'
import { CollectionStatusProvider } from './contexts/CollectionStatusContext'
import PrivateRoute from './components/PrivateRoute'
import Dashboard from './components/Dashboard'
import Login from './components/Login';
import Calendar from './components/Calendar';

import { GlobalStyle } from './GlobalStyle';

function App() {
  return (
    <AuthProvider>
      <CalendarProvider>
        <CollectionStatusProvider>
          <CollectionsProvider>
            <div className="App">
              <Router>
                {/* <leave header out of routes bc its used on all pages. maybe remove div cllassname "app" /> */}
                <Routes>
                  <Route path="/" element={<PrivateRoute />}>
                    <Route path="/" element={<Dashboard />} />
                  </Route>
                  <Route path="/" element={<PrivateRoute />}>
                    <Route path="/:category/:collFilter" element={<Dashboard />} />
                  </Route>
                  <Route path="/" element={<PrivateRoute />}>
                    <Route path="/:category/:collFilter/:selectedCollection" element={<Dashboard />} />
                  </Route>
                  {/* <Route path="/" element={<PrivateRoute />}>
                    <Route path="/artists/:collFilter" element={<Dashboard />} />
                  </Route> */}
                  <Route path="/login" exact element={<Login />} />

                  <Route path="/calendar" exact element={<Calendar />} />

                </Routes>
                <GlobalStyle />
              </Router>
            </div>
          </CollectionsProvider>
        </CollectionStatusProvider>
      </CalendarProvider>
    </AuthProvider>
  );
}

export default App;
