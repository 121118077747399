import styled from "styled-components";

export const Wrapper = styled.div`;
outline: 1px solid #000000;
display: grid;
grid-template-columns: 7.2rem repeat(3,min-content) 1fr;
grid-column-gap: 10px;
align-items: center;
width: 100%;
z-index: 1;
user-select: none;
padding: 5px 15px;
background: var(--uiollyPurple);

h1{
    font-size: 16px;
    text-transform: uppercase;
    white-space: nowrap;
    text-align: right;
}
`;

export const Month = styled.h1`
    font-size: 16px;
    text-transform: uppercase;
    text-align: left !important;
    white-space: nowrap;
    
`;

export const NavButton = styled.div`
height: 30px;
cursor: pointer;
position: relative;
width: 30px;
display: flex;
align-items: center;
border-radius: .1rem;
font-size: 10px;
padding: 10px;
transform: ${props => props.direction === 'left' ? 'rotate(180deg)' : 'undefined'};
img{
    max-height: 100%;
    max-width: 100%;
    position: relative;
}
`;

export const TodayButton = styled.div`
padding: 10px 25px;
user-select: none;
border-radius: .2rem;
cursor: pointer;
font-size: 10px;
margin: 5px;
text-transform: uppercase;
background: #ffffff;
border: 1px solid #000000;
box-shadow: 0 0 5px 2px rgba(0,0,0,0.2);
`;