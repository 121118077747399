import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useCalendar } from '../../contexts/CalendarContext'
import { useCollections } from '../../contexts/CollectionsContext'
import { getMonth, getMonthUnixRange } from '../../utils.js'
import CalendarHeader from './CalendarHeader'
import SideBar from './SideBar'
import Month from './Month'
import EventModal from './EventModal'
import {
    Wrapper,
    InnerWrapper,
    FlexRow
} from './Calendar.styles'

// Hook
import { useCollectionSelectionHook } from '../../hooks/useCollectionSelectionHook';

import API from '../../API'

const Calendar = ({ }) => {
    const { monthIndex,
        monthUnixRange,
        setMonthUnixRange,
        setMonthIndex,
        selectedDay,
        showEventModal,
        setShowEventModal,
        closeEventModal,
        savedEvents,
        setScheduledCollections,
        getRangedCollections,
        dispatchCalendarEvent
    } = useCalendar();
    const location = useLocation();
    const { collection, removeSelection } = useCollections();
    const [currentMonth, setCurrentMonth] = useState(getMonth());
    useEffect(() => {
        if (collection !== null)
            if ((collection.status === 'PENDING' || collection.status === '1'))
                removeSelection()
    }, [collection])
    useEffect(() => {
        const newMonth = getMonth(monthIndex)
        setMonthUnixRange(getMonthUnixRange(newMonth))
        getRangedCollections(getMonthUnixRange(newMonth));
        setCurrentMonth(newMonth)
    }, [monthIndex])

    return (
        <FlexRow>
            <SideBar />
            <Wrapper>
                <CalendarHeader month={currentMonth} />
                <InnerWrapper>
                    <Month month={currentMonth} />
                </InnerWrapper>
                {collection && <EventModal modalVisible={showEventModal} closeModal={closeEventModal} collection={location.state !== null ? location.state.collection : undefined} />}
            </Wrapper>
        </FlexRow>
    )
}

export default Calendar
