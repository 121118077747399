import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import {
    Wrapper,
    // SideBar,
    SideBarLink,
    RightContainer,
    SideBarLinkHeader,
    Row,
    Innercontainer,
    DateWrapper,
    SiteHeader,
    Spacer,
    MessagesLink,
    ScrollContainer
} from './Dashboard.styles'

import Collections from './Collections'
import ProcessPendingBar from './ProcessPendingBar'
import SideBar from '../SideBar'
import FeaturedArtists from './FeaturedArtists'
import { returnMonth } from '../../helpers'
import { useImportCollectionStatus } from '../../contexts/CollectionStatusContext'
import { useCollectionSelectionHook } from '../../hooks/useCollectionSelectionHook'

import API from '../../API'

import {
    NEW_ARTISTS,
    ACCEPTED_ARTISTS,
    FEATURED_ARTISTS,
    NEW_COLLECTIONS,
    SCHEDULED_COLLECTIONS,
    UNSCHEDULED_COLLECTIONS,
    RESCHEDULE_REQUIRED,
    PAST_COLLECTIONS
} from '../../config'

const Content = ({ collectionStatusKey, setItem, breadcrumb, setBreadcrumb, adminID, setError, setLoading, content, values, loading, error, handleArtistAssignment, setProcessArtist, playing, nowPlaying, handleSongChange }) => {

    if (content.type === 'Collections' || content.value === NEW_ARTISTS) {
        return <Collections
            filter={content}
            collectionStatusKey={collectionStatusKey}
            setItem={setItem}
            content={content}
            breadcrumb={breadcrumb}
            setBreadcrumb={setBreadcrumb}
        ></Collections>
    }
    else if (content.type.toUpperCase() === 'Artists'.toUpperCase() && content.value.toUpperCase() === FEATURED_ARTISTS.toUpperCase()) {
        return <FeaturedArtists />
    }
    else {
        return <></>
    }



}

const Dashboard = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const { collFilter, selectedCollection, category } = useParams();
    console.log(location.pathname, "location", collFilter)
    const [breadcrumb, setBreadcrumb] = useState(null);
    const [item, setItem] = useState(null);
    const [loading, setLoading] = useState(true)
    const [adminID, setAdminID] = useState('1')
    const [error, setError] = useState(false)
    const { statusKey } = useImportCollectionStatus();
    const now = new Date().toLocaleString().split(',')[0]
    const todaysDate = "Today's Date: " + returnMonth(now, true) + ' ' + now.split('/')[1];
    const initialSideBarLinks = {
        Collections: [],
        Artists: [
            // {
            //     value: ACCEPTED_ARTISTS,
            //     text: ACCEPTED_ARTISTS
            // },
            {
                value: FEATURED_ARTISTS,
                text: FEATURED_ARTISTS
            }
        ]
    };
    const [sidebarlinks, setSidebarlinks] = useState(initialSideBarLinks)


    const [content, setContent] = useState({
        type: category ? category : Object.keys(sidebarlinks)[0],
        value: collFilter ? collFilter : Object.keys(sidebarlinks)[0][0].value,
        text: collFilter ? collFilter : Object.keys(sidebarlinks)[0][0].text
    })
    useEffect(() => {
        if (location.pathname.split('/').length === 2) {
            setBreadcrumb(null)
        }
    }, [location])

    useEffect(() => {
        if (statusKey.length > 0) {

            statusKey.forEach(status => {
                console.log(status)
                if (!sidebarlinks.Collections.map(link => (link.text)).includes(status.status))
                    setSidebarlinks(prev => ({ ...prev, Collections: [...prev.Collections, { value: status.id, text: status.status }] }))
            })
        }
    }, [statusKey])

    useEffect(() => {
        if (category === undefined || category.toUpperCase() === 'Collections'.toUpperCase()) {
            statusKey.forEach(status => {
                if (collFilter !== undefined && status.status === collFilter.toUpperCase())
                    setContent({
                        type: 'Collections',
                        value: status.id,
                        text: status.status
                    })
            })
            if (collFilter === undefined && statusKey.length) {
                setContent({
                    type: 'Collections',
                    value: statusKey[0].id,
                    text: statusKey[0].status
                })
            }
        }
        else if (category.toUpperCase() === 'Artists'.toUpperCase()) {
            setContent({
                type: 'Artists',
                value: collFilter,
                text: collFilter
            })
        }
        setLoading(false)
    }, [collFilter, statusKey])
    const toScheduler = () => {
        navigate('/calendar', { state: { collection: item } })
    }

    if (loading || sidebarlinks === undefined || sidebarlinks === null || content.value === undefined) {
        return (
            <Wrapper>
                Loading
            </Wrapper>
        )
    }
    else {
        return (
            <Wrapper>
                <SideBar
                    title={"Admin"}
                    currentContent={content}
                    setCurrentContent={setContent}
                    sidebarlinks={sidebarlinks}
                    onClick={(e) => {
                        console.log(e.target)
                        setContent({
                            type: e.target.parentElement.attributes.value.value,
                            value: e.target.attributes.value.value,
                            text: e.target.innerText
                        })
                        setBreadcrumb(null)
                        setItem(null)
                        navigate(`/${e.target.parentElement.attributes.value.value.toLowerCase()}/${e.target.innerText.toLowerCase()}`, { state: { collection: item } })
                    }
                    }
                />
                <RightContainer>
                    <Row>
                        <div>
                            <div onClick={() => setBreadcrumb(null)}>{content.text} {content.value !== NEW_ARTISTS ? content.type : null}</div>
                            <div>{breadcrumb !== null ? ' / ' : ''}</div>
                            <div>{breadcrumb !== null ? breadcrumb : ''}</div>
                        </div>
                        <DateWrapper>{todaysDate}</DateWrapper>
                    </Row>
                    <Innercontainer>
                        <ScrollContainer>
                            <Content
                                collectionStatusKey={statusKey}
                                breadcrumb={breadcrumb}
                                setItem={setItem}
                                setBreadcrumb={setBreadcrumb}
                                setLoading={setLoading}
                                setError={setError}
                                content={content}
                            />
                        </ScrollContainer>
                    </Innercontainer>
                    <ProcessPendingBar toScheduler={toScheduler} item={item} content={content} setBreadcrumb={setBreadcrumb} filter={content.value} visible={breadcrumb !== null}></ProcessPendingBar>
                </RightContainer>
            </Wrapper >
        )
    }

}

export default Dashboard
