import React, { useState } from 'react'
import dayjs from 'dayjs'
import {
    ModalOverlay,
    ModalContent,
    Modal,
    CloseButtonContainer,
    SmallText,
    Title,
    Tile,
    TimeWrapper,
    DateWrapper,
    TimeInput,
    InputField,
    ButtonWrapper,
    GridItem,
    Label
} from './EventModal.styles'

import { useCalendar } from '../../../contexts/CalendarContext'
import { useCollections } from '../../../contexts/CollectionsContext'
import Button from '../../Button'
import Dropdown from '../../Dropdown'
import CollectionTile from '../CollectionTile'
import { formatTimeZone } from '../../../utils'
import { useAuth } from '../../../contexts/AuthContext'
import { useImportCollectionStatus } from '../../../contexts/CollectionStatusContext'
import API from '../../../API'
import CloseIcon from '../../../images/close.svg'

const Close = ({ closeModal }) => (
    <CloseButtonContainer >
        <img src={CloseIcon} alt="close-popup" onClick={closeModal} />
    </CloseButtonContainer>
)

const EventModal = ({ closeModal, modalVisible }) => {
    const { currentUser } = useAuth();
    const { statusKey } = useImportCollectionStatus();
    const { fetchCollections, setCollection, collections, collection, onSelectionCollection } = useCollections();
    const {
        selectedDay,
        monthUnixRange,
        getRangedCollections,
        closeEventModal,
        setSelectedDay,
        dispatchCalendarEvent,
        setSelectedTime,
        selectedTime,
        setDateAndTime,
        setRunTime,
        runTime
    } = useCalendar();
    const [options, setOptions] = useState([{ value: '', innerText: '' }])
    const [menuOpen, setMenuOpen] = useState(false);
    const localTimeZoneAbbrev = formatTimeZone((dayjs().utcOffset() / 60).toString())
    const handleFieldInput = (event) => {
        let value;
        const input = event.target.name;
        console.log("input is", input)
        if (event.target.value !== undefined) {
            value = event.target.value;
        } else {
            value = event.target.attributes.value.value;
        }
        value = value.replace(/\D/g, '');
        value = value !== null && value !== '' ? parseInt(value) : 0
        if (input === 'hours' && value >= 24) {
            console.log(runTime.days)
            const new_d = parseInt(runTime.days !== '' && runTime.days !== null ? runTime.days : 0) + (value - value % 24) / 24;
            const new_h = value % 24
            const new_time = selectedDay.hour() + new_d * 24 + new_h
            setRunTime(prev => ({
                days: new_d,
                [input]: new_h,
                endDate: dayjs(selectedDay).hour(new_time)
            }))
        }
        else if (input === 'hours') {
            const new_d_in_hr = parseInt(runTime.days !== '' && runTime.days !== null ? runTime.days : 0) * 24
            const new_h = value
            const total_h = new_d_in_hr + new_h
            console.log(new_d_in_hr, total_h)
            setRunTime(prev => ({
                ...prev,
                [input]: value !== 0 ? value : '',
                endDate: dayjs(selectedDay).hour(total_h + selectedDay.hour())
            }))
        }
        else {
            const new_d_in_hr = parseInt(value !== '' && value !== null ? value : 0) * 24

            const new_h = parseInt(runTime.hours !== '' && runTime.hours !== null ? runTime.hours : 0)
            const total_h = new_d_in_hr + new_h
            console.log("new days", value, new_d_in_hr, total_h, new_h)
            setRunTime(prev => ({
                ...prev,
                [input]: value !== 0 ? value : '',
                endDate: dayjs(selectedDay).hour(total_h + selectedDay.hour())
            }))
        }


    }
    const validateFields = () => {
        if (runTime.hours === null && runTime.days === null) { return false }
        else if (runTime.hours === 0 && runTime.days === 0) { return false }
        else if (runTime.hours === '' && runTime.days === '') { return false }
        else if (selectedTime === '') { return false }
        else return true

    }
    console.log(selectedTime, "selected time", selectedDay, selectedDay.unix())


    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateFields()) {
            const selTime = `${selectedDay.format('YYYY MMMM DD')} ${selectedTime.split(":")[0]}:${selectedTime.split(":")[1]}`
            const selDateFull = new Date(selTime).getTime();
            setDateAndTime(selDateFull)
            const calendarEvent = {
                title: collection.name,
                artist: {
                    id: collection.artist.id,
                    handle: collection.artist.handle
                },
                collection_id: collection.collection_id,
                cover_art: collection.image,
                description: 'descr',
                dateAndTime: selectedDay.unix(),//selDateFull, // ms changing to s
                endDate: runTime.endDate !== '' ? runTime.endDate.unix() : '', // s
                id: Date.now()
            }
            //dispatchCalendarEvent({ type: 'push', payload: calendarEvent })
            const r = submitReleaseDate(selectedDay.unix(), calendarEvent.endDate).then(() => {
                getRangedCollections(monthUnixRange)
                const unscheduled_status = statusKey.filter(status => status.status === 'UNSCHEDULED')[0].id
                fetchCollections(unscheduled_status).then((cols) => {
                    console.log("current length of collections", cols.length, cols)
                    if (cols.length)
                        onSelectionCollection(cols[0].collection_id)
                    else
                        setCollection(null)
                })
            })
            closeEventModal()
        }


    }
    //const submitReleaseDate = async (utc_start_date_ms, utc_end_date_s) => {
    const submitReleaseDate = async (utc_start_date_s, utc_end_date_s) => {
        try {
            const res = await API.scheduleCollection(currentUser.accessToken, currentUser.email, collection.collection_id, utc_start_date_s, utc_end_date_s)
        } catch (err) {
            console.log(err)
        }
    }
    const title = selectedDay !== null ? selectedDay.format('dddd, MMMM DD') : ''
    console.log("collections", collections)
    return (
        <ModalOverlay modalVisible={modalVisible}>
            <Modal modalVisible={modalVisible}>
                <Title>Schedule Drop</Title>
                <Close closeModal={closeModal} />
                <ModalContent>
                    <DateWrapper>{title}</DateWrapper>
                    <TimeWrapper justifyContent={"flex-start"}><TimeInput type="time" onChange={(e) => {
                        setSelectedTime(e.target.value);
                        if (e.target.value.split(':').length > 1)
                            setSelectedDay(prev => prev.hour(e.target.value.split(':')[0]).minute(e.target.value.split(':')[1]))
                    }} value={selectedTime} /> {localTimeZoneAbbrev}</TimeWrapper>
                    <Label gridArea="labelDate">Start Date:</Label>
                    <Label gridArea="labelCollection">Collection:</Label>
                    <Label gridArea="labelStartTime">Start Time:<br/><SmallText>[hour:min AM/PM]</SmallText></Label>
                    <Label gridArea="labelRunTime">Run Time:</Label>
                    <GridItem gridArea="runTime">
                        <div>
                            <div><InputField placeholder="d" type="text" name="days" value={runTime.days} onChange={(e) => { handleFieldInput(e) }} /></div>
                            <div>days</div>
                        </div>
                        <div>
                            <div>
                                <InputField placeholder="hh" type="text" name="hours" value={runTime.hours} onChange={(e) => { handleFieldInput(e) }} />
                            </div>
                            <div>hours</div>
                        </div>
                    </GridItem>
                    <Tile>
                        <CollectionTile
                            sidebar
                            isSelected={true}
                            collection={collection}
                        />
                    </Tile>
                    <ButtonWrapper><Button width="100%" text={"Add"} onClick={handleSubmit} /></ButtonWrapper>
                </ModalContent>
            </Modal>
        </ModalOverlay>
    )
}

export default EventModal
