import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";

export const Wrapper = styled.aside`
    user-select: none;
    text-transform: uppercase;
    letter-spacing: 0.03rem;
    border-right: var(--sideBarBorder);
    justify-content: flex-start;
    width: 30%;
    max-width: 250px;
    height: 100vh;
    position: relative;
    padding: 40px 0px 20px;
    padding: 0 0px 20px;
    background: var(--sideBarBackground);
`;

export const SideBarSectionHeader = styled.div`
    padding: 10px 20px;
    font-weight: 500;
    font-size: 15px;
`;


export const SideBarTitle = styled.div`
    padding: 0 20px ;
    font-size: 12px;
    display: flex;
    position: relative;
    align-items: center;
    text-transform: uppercase;
`;

export const NavLink = styled.div`

    padding: 0 20px ;
    height: 50px;
    display: flex;
    position: relative;
    align-items: center;
    text-transform: uppercase;
`;

export const ArrowButton = styled.div`
    height: 20px;
    width: min-content;
    padding: 5px 0;
    display: flex;
    align-items: center;

    margin-right: 5px;
    justify-content: flex-start;
    img {
        height: 100%;
        transform: rotate(180deg);
    
    }
`;

export const Spacer = styled.div`
margin-top: 20px;
`;

export const CollectionTileWrapper = styled.div`
    height: 50px;
    width: 100%;
    opacity: ${props => props.isSelected ? 1 : 0.5 };
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    border-radius: 5px;
    padding: 5px;
    margin: 5px 0;
    background-color: #000000;
    background-image: ${props => props.backgroundIMG ? `url(${props.backgroundIMG})` : '#ffb375'};
`;

export const SideBarPadding = styled.div`
padding: 0 20px ;
`;