import React, { useRef } from 'react'

import {
    ModalOverlay,
    ModalWrapper,
    GridItem,
    ModalTitle,
    SubText,
    CloseButtonContainer
} from './Modal.styles'

import Close from '../../images/close.svg'

// components
import Button from '../Button'

const ClosePopup = ({ closeModal, onClose }) => (
    <CloseButtonContainer >
        <img src={Close} alt="close-popup" onClick={()=>{closeModal();onClose();}} />
    </CloseButtonContainer>
)


const Modal = ({ onClose, showModal, children, closeModal, subtextSize, title, subtext, onClick, buttonText, disabled }) => {
    const overlayRef = useRef(null)
    return (
        <ModalOverlay ref={overlayRef} showModal={showModal} onClick={(e) => { console.log(e.target); if (e.target === overlayRef.current) {closeModal();onClose();} }}>
            <ModalWrapper>
                <GridItem gridArea="title">
                    <ModalTitle>{title}</ModalTitle>
                </GridItem>
                <GridItem gridArea="close">
                    <ClosePopup closeModal={closeModal} onClose={onClose}/>
                </GridItem>
                <GridItem gridArea="subtext" >
                    <SubText size={subtextSize}>
                        {subtext}
                    </SubText>
                </GridItem>
                <GridItem gridArea="content">
                    {children}
                </GridItem>
                <GridItem gridArea="button">
                    <Button
                        disabled={disabled !== undefined ? disabled : undefined}
                    width="100%"
                    background="transparent"
                    text={buttonText ? buttonText : 'Close'}
                    onClick={onClick !== undefined ? onClick : closeModal}
                    />
                </GridItem>
            </ModalWrapper>
        </ModalOverlay>
    )
}

export default Modal
