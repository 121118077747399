import styled from "styled-components";

export const Wrapper = styled.div`
height: 100vh;
position: relative;
flex-grow: 1;
display: flex;
flex-direction: column;
padding: 50px;
`;

export const InnerWrapper = styled.div`
display: flex;
flex-grow: 1;
`;

export const FlexRow = styled.div`
display: flex;
`;