import React, { useEffect } from 'react'

import {
    Wrapper,
    ArtworksContainer,
    CoverArt,
    CollectionInfo,
    CollectionInfoBgd,
    Artist,
    Title,
    SubTitle,
    Description
} from './CollectionPreview.styles'

import ArtworkCard from '../ArtworkCard'



const CollectionPreview = ({ collection, breadcrumb }) => {
   
    return (
        <Wrapper>
            <CoverArt>
                <img src={collection.image} alt={collection.name} />
                <CollectionInfoBgd>
                    <CollectionInfo>
                        <Artist>{collection.artist.first_name} {collection.artist.last_name}</Artist>
                        <Title>{collection.name}</Title>
                    </CollectionInfo>
                </CollectionInfoBgd>
            </CoverArt>
            <Description>{collection.description}</Description>
            <SubTitle>Artworks</SubTitle>
            <ArtworksContainer>
                {
                    collection.items.map(artwork => (
                        <ArtworkCard
                            key={artwork.id}
                            market='primary'
                            value={artwork}
                            collectionState='pending'
                            artist={collection.artist.name}
                        />
                    ))
                }
            </ArtworksContainer>
        </Wrapper>
    )
}

export default CollectionPreview
