import React from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import {
    Wrapper,
    SiteHeader,
    SideBarWrapper,
    Spacer,
    SideBarLink,
    SideBarLinkHeader,
    NavLink
} from './SideBar.styles'

const SideBar = ({ title, sidebarlinks, onClick, currentContent }) => {
    const navigate = useNavigate()
    const { logout } = useAuth()
    return (
        <SideBarWrapper>
            <SiteHeader>{title}</SiteHeader>
            {
                Object.keys(sidebarlinks).map((category, index) => (
                    <Spacer key={index} value={category}>
                        <SideBarLinkHeader key={category}>{category}</SideBarLinkHeader>
                        {sidebarlinks[category].map(link => (
                            <SideBarLink
                                value={link.value}
                                key={category + link.value}
                                isActive={currentContent.value.toUpperCase() === link.value.toUpperCase() && currentContent.type.toUpperCase() === category.toUpperCase() ? true : false}
                                onClick={onClick}
                            >
                                {link.text}
                            </SideBarLink>
                        ))
                        }
                    </Spacer>
                )
                )
            }
            <Spacer><SideBarLinkHeader onClick={() => { navigate('/calendar') }}>Calendar</SideBarLinkHeader></Spacer>
            <Spacer><SideBarLinkHeader onClick={() => {
                logout()
                    .then((userCred) => {
                        navigate('/')
                    })
                    .catch((error) => {
                        const errorCode = error.code;
                        console.log(errorCode)
                    })
            }}>Logout</SideBarLinkHeader></Spacer>
        </SideBarWrapper>
    )
}

export default SideBar
