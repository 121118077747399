import styled from "styled-components";

export const Wrapper = styled.div`
    outline: var(--processPendingBorder);
    position: relative;
    height: 80px;
    padding: 0 60px;
    width: 100%;
    z-index: 2;
    bottom: 0;
    display: grid;
    grid-template-areas: 'details1 details2 email action';
    grid-column-gap: 20px;
    grid-template-rows: 1fr;
    grid-template-columns: min-content min-content 1fr min-content;
    background: var(--processPendingBackground);
`;


export const AcceptRejectWrapper = styled.div`

    width: 100%;
    z-index: 2;
    bottom: 0;
    display: grid;
    grid-template-areas: 'accept reject';
    grid-column-gap: 20px;
    grid-template-rows: 1fr;
    grid-template-columns: 20px 20px;
    background: var(--processPendingBackground);
`;




export const Action = styled.div`
img{
    width: 20px;
    height: 20px;
    cursor: pointer;
}

position: relative;
display: flex;
align-items: center;
justify-content: center;
`;

export const RejectButtonContainer = styled(Action)`
grid-area: reject;
`;

export const AcceptButtonContainer = styled(Action)`
grid-area: accept;
`;

export const PopupWrapperOverlay = styled.div`
position: absolute;
display: flex;
justify-content: center;
align-items: center;
overflow: hidden;
width: ${props => props.visible ? '100%' : '0'};
height: ${props => props.visible ? '100vh' : '0'};
left: 0;
top: 0;
background: var(--overlayBgd);
z-index: 2;
visibility: ${props => props.visible ? 'visible' : 'hidden'};
`;

export const ButtonContainer = styled.div`

height: 100%;
display: flex;
flex-direction: column;
justify-content: flex-end;
width: 100%;
align-content: end;
`;

export const ScheduleButton = styled.div`
height: 100%;
display: flex;
flex-direction: column;
white-space: nowrap;
justify-content: center;
width: 100%;
align-content: end;
`;

export const PopupWrapper = styled.div`
width: 400px;
display: grid;
grid-template-rows: repeat(4,min-content);
z-index: 1;
visibility: ${props => props.visible ? 'visible' : 'hidden'};
grid-row-gap: 10px;
text-align: center;
padding: 20px;
user-select: none;
background: var(--background);
`;
export const Subtext = styled.div`
display: flex;
text-align: center;
text-transform: uppercase;
flex-direction: column;
justify-content: center;
align-items: center;
`;

export const ValueHighlighted = styled.div`
font-weight: 600;
text-transform: none;
letter-spacing: 0.03rem;

`;

export const Strong = styled.strong`
color: ${props => props.action === 'accept' ? '#03cc00' : '#9e0000'};
text-transform: uppercase;
white-space: pre;
`;

export const CloseButtonContainer = styled.div`
display: flex;
width: 100%;
justify-content: flex-end;
position: relative;
img{
height: 20px;
width: 20px;
cursor: pointer;
}
`;

export const DetailsWrapper = styled.div`
display: flex;
flex-direction: column;
user-select: none;
white-space: nowrap;
justify-content: center;
`;

export const Filter = styled.div`
text-transform: uppercase;
font-size: 10px;
margin-top: 0.1rem;
`;

export const Title = styled.div`

`;

