import dayjs from 'dayjs';

export function getMonth(month = dayjs().month()) {
    const year = dayjs().year();
    // 0 - 6 representing day of the week (Sunday - Saturday)
    const firstDayOfMonth = dayjs(new Date(year, month, 1)).day()

    let currMonthCount = 0 - firstDayOfMonth; // first day is wednesday, then -3
    // 5 rows and 7 columns (days/wk) for each month
    const daysMatrix = new Array(5).fill([]).map(() => {
        return new Array(7).fill(null).map(() => {
            // day
            currMonthCount++
            return dayjs(new Date(year, month, currMonthCount))
        })
    })
    return daysMatrix
}

export const getMonthUnixRange = (month) => { 
    return [month[0][0].unix(), month[month.length - 1][month[month.length - 1].length - 1].unix()]
}

export function getFormattedDate(d) {
    return new Date(d)
}

export const formatTimeZone = (utc_offset) => {
    const utc_time = `UTC ${utc_offset.substring(0, 1)} ${utc_offset.substring(1)}`
    const usa_zones = [
        {
            abbreviation: 'AST',
            offset: '-4'
        },
        {
            abbreviation: 'EST',
            offset: '-5'
        },
        {
            abbreviation: 'CST',
            offset: '-6'
        },
        {
            abbreviation: 'MST',
            offset: '-7'
        },
        {
            abbreviation: 'PST',
            offset: '-8'
        },
        {
            abbreviation: 'AKST',
            offset: '-9'
        },
        {
            abbreviation: 'HST',
            offset: '-10'
        },
        {
            abbreviation: 'SST',
            offset: '-11'
        },
        {
            abbreviation: 'CHST',
            offset: '+10'
        }
    ]
    const zone = usa_zones.filter(zone => zone.offset === utc_offset);
    return zone !== undefined ? zone[0].abbreviation : utc_time;
}