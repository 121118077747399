const VALID_FILE_TYPES = {
    collectionCover: ["image/jpeg", "image/png"],
    collectionArtwork: ["image/jpeg", "image/png", "audio/mpeg", "audio/wav", "video/mpeg", "video/mp4"]
};
const LOGIN_POPUP = 'Log In';
const SIGNUP_POPUP = 'Sign Up';
const LOGOUT = 'Log Out';
const SET_PASSWORD_POPUP = 'Set Password';
const ACCEPT = {value: 1, text: 'Accept'};
const REJECT = {value: 0, text: 'Reject'};
const NEW_ARTISTS = 1;
const ACCEPTED_ARTISTS = "Accepted";
const FEATURED_ARTISTS = "Featured";
const NEW_COLLECTIONS = 3;
const SCHEDULED_COLLECTIONS = 4;
const UNSCHEDULED_COLLECTIONS = 5;
const PAST_COLLECTIONS = 6;
const MAX_SIZE = 500000000;
// zero means no limit
const MAX_UPLOADS =
{
    BASIC: 1,
    PLUS: 0,
    ULTRA: 0
};
const PENDING = 'PENDING';
const UNSCHEDULED = 'UNSCHEDULED';
const UNMINTED = 'UNMINTED';
const RESCHEDULE_REQUIRED = 'NEEDS RESCHEDULE';
const SCHEDULED = 'SCHEDULED';
const UPCOMING = 'UPCOMING';
const LIVE = 'LIVE';
const PAST = 'PAST';
export {
    VALID_FILE_TYPES,
    MAX_SIZE,
    MAX_UPLOADS,
    LOGIN_POPUP,
    SIGNUP_POPUP,
    SET_PASSWORD_POPUP,
    LOGOUT,
    NEW_ARTISTS,
    ACCEPTED_ARTISTS,
    FEATURED_ARTISTS,
    NEW_COLLECTIONS,
    SCHEDULED_COLLECTIONS,
    UNSCHEDULED_COLLECTIONS,
    PAST_COLLECTIONS,
    ACCEPT,
    REJECT,
    PENDING,
    UNSCHEDULED,
    RESCHEDULE_REQUIRED,
    UNMINTED,
    UPCOMING,
    LIVE,
    PAST
};