import React, { useContext, useState, useEffect } from 'react'
import { auth } from '../firebase'
import {
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signOut
} from "firebase/auth";
import API from '../API'
const AuthContext = React.createContext()

export const useAuth = () => {
    return useContext(AuthContext)
}


export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null)
    const signup = (email, password) => {
        return createUserWithEmailAndPassword(auth, email, password)
    }
    const login = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password)
    }
    const logout = (email, password) => {
        return signOut(auth)
    }
    const _retrieveUserDetails = async (email) => {
        try {
            const additionalDetails = await API.fetchCurrentUserAdditionalDetails(email)
            setCurrentUser(prev => ({ ...prev, userDetails: additionalDetails }))
        }
        catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setCurrentUser(user);
                try {
                    const settoken = API.setAccessToken(user.email, user.accessToken)
                }
                catch (e) {
                    console.log(e)
                }
                _retrieveUserDetails(user.email)
            }
            else {
                console.log("user has logged out")
                setCurrentUser(null);
            }

        })
        return unsubscribe // unsubscribe from listener on unmount
    }, [])
    const value = { currentUser, signup, login, logout, _retrieveUserDetails }
    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

