import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    :root{
        --maxWidth: 1280px;
        --background: #fff;
        --overlayBgd: rgba(0,0,0,0.75); //opposite of background
        --black: #000;
        --defOutline: 1px solid #000000;
        --fontColor: #000000;
        --defaultFont: 'Poppins', sans-serif;
        --cardWidth: 350px; //hexagon
        --cardHeight: 350px;
        --fontSmall: 11px;
        --fontMedium: 1.1em;
        --lightestGrey: #F2F2F2;
        --lighterGrey: #DDDDDD;
        --lightGrey: #CCCCCC;
        --grey: #1A1A1A;
        --uiollyGrey: #B3B3B3;
        --uiollyPurple: #857AFF;
        --goldSolid: rgb(252, 216, 88);
        --goldShadowCentered: 0 0 10px 0 var(--goldSolid);
        --gold80: rgba(252, 216, 88, .8);
        --headerHeight: 60px,
        --fontBig: 1.5em;
        --buttonBackground: #000;
        --buttonBorderRadius: 5px;
        --buttonBorder: 1px solid #000000;
        --buttonDisabledBgd: var(--lightestGrey);
        --buttonDisabledBorder: 2px solid #9C9C9C;
        --buttonDisabledText: #9C9C9C;
        --buttonLetterSpacing: 0.15rem;
        --buttonFontWeight: 700;
        --buttonFontSize: 11px;
        --buttonHeight: 56px;
        --purpleGrey: #696980;
        --red: #C1272D;
        --defaultInputHeight: 49px;
        --badgeSize: 37px;
        --artworkCardWidth: 200px; // general cards
        --artworkCardHeight: '';
        --artworkCardBorder: 1px solid #000000;
        --artworkCardBorderRadius: 0;
        --artworkCardBackground: #ffffff;
        --artworkCardImageSize: 200px;
        --artworkCardArtistFontSize: 10px;
        --artworkCardArtistFontWeight: 600;
        --letterSpacingCommon: 0.02rem;
        --artworkCardTitleFontSize: 13px;
        --artworkCardTitleFontWeight: 400;
        --artworkCardDetailsPadding: 20px;
        --artworkCardPriceFontColor: #000000;
        --artworkCardPriceFontSize: 12px;
        --artworkCardEditionFontSize: 10px;
        --artworkCardEditionFontColor: var(--uiollyGrey);
        --goldGradient: -webkit-linear-gradient( 90deg,
        #FBC926, #FFF5B7, #C3922E, #EED688, #FFFBCC, #FEED9E, #FCD243 );
        --goldGradientLR: -webkit-linear-gradient( 0deg,
        #FBC926, #FFF5B7, #C3922E, #EED688, #FFFBCC, #FEED9E, #FCD243 );
        --formBackground: #000000;
        --sideBarBorder: 1px solid #000000;
        --backgroundActiveLi: var(--uiollyPurple);
        --sideBarBackground: #ffffff; //linear-gradient( 90deg,  #000000, rgba(255,255,255,0.1))
        --adminBackground: #ffffff;
        --processPendingBackground: #ffffff;
        --processPendingBorder: 1px solid #000000;
    }

    * {
        box-sizing: border-box;
        font-family: 'Poppins', sans-serif; //'Poppins', sans-serif;
    }

    body{
        margin: 0;
        padding: 0;
        background: var(--background);
        color: var(--fontColor);
        overflow-x: hidden;
        width: 100%;
    }
    html{
        margin: 0;
        width: 100%;
            font-size: 15px;
            font-family: 'Poppins';
            h1{
            user-select: none;
            text-transform: uppercase;
            /* margin: 0; */
            font-size: 2em;
            line-height: 2.2rem;
            font-weight: 700;
            letter-spacing: 0.07rem;
            /* margin-bottom: 25px; */
        }
        h2{
            user-select: none;
            text-transform: uppercase;
            font-size: 2em;
            line-height: 2.2rem;
            font-weight: var(--bold);
            letter-spacing: 0.07rem;
        }
        h4{
            font-weight: var(--boldSmall);
            font-size: .9em;
            line-height: 1.2rem;
            letter-spacing: 0.07rem;
            margin: 0;
            text-transform: uppercase; 
            /* font-weight: var(--bold);
font-size: 16px;
line-height: 1.2rem;
letter-spacing: 0.07rem;
text-transform: uppercase; */
        }
        p{
            font-weight: 300;
            
        }
        a{
            display: flex;
            text-decoration: none;
            color: var(--fontColor);
        }
    }
    @media screen and (min-width:768px) {
    html {
        font-size: 16px;
    }
    }

    @media screen and (min-width:1024px) {
    html {
        font-size: 16px;
    }
    }

    @media screen and (min-width:1300px) {
    html {
        font-size: 18px;
    }
}
`;