import styled from "styled-components";

export const Text = styled.div`
font-size: 12px;
`;

export const ActiveArtistSection = styled.div`
background: var(--lightestGrey);
display: grid;
grid-template-rows: min-content min-content min-content 1fr;
`;

export const Bid = styled.div`
display: flex;
justify-content: space-between;
cursor: pointer;
padding: ${props => props.isHeader ? '10px' : '10px 20px'};
font-size: 12px;
:nth-of-type(2n){
    background: var(--lightestGrey);
}
`;

export const Header = styled(Bid)`
font-size: 10px;
text-transform: uppercase;
color: var(--secondary);
background: var(--background) !important;
`;

export const Row = styled.div`
display: grid;
grid-template-columns: 2fr 1fr;
grid-template-areas: 'label label''input min';
`;

export const MinBid = styled.div`
font-size: 12px;
background: var(--black);
white-space: nowrap;
display: flex;
align-items: center;
padding: 5px;
/* outline: 2px solid var(--secondary); */
outline-offset: -2px;
grid-area: min;
text-transform: uppercase;
color: var(--background);
`;

export const Label = styled.div`
font-size: var(--fontSmall);
text-transform: uppercase;
letter-spacing: 0.05rem;
grid-area: label;
font-weight: 700;
margin-bottom: 0.8rem;
`;

export const AddArtistWrapper = styled.div`
cursor: pointer;
width: 30px;
height: 30px;
padding: 5px;

`;

export const ArtistWrapper = styled.div`
border: var(--defOutline);
background: var(--background);
display: grid;
grid-template-columns: min-content 1fr min-content;
align-items: center;
margin: 15px 0;
border-radius: 30px;
padding: 5px;

`;


export const ProfilePicture = styled.div`
width: 25px;
height: 25px;
border-radius: 50%;
overflow: hidden;
img{
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}
`;

export const ArtistName = styled.div`
`;

export const ScrollWrapper = styled.div`
overflow: auto;
max-height: 100%;
border: 1px solid var(--lightestGrey);
position: relative;
font-size: 14px;
`;

export const ScrollContent = styled.div`
height: 100%;
scroll-behavior: smooth;
display: grid;
grid-row-gap: 10px;
white-space: nowrap;
grid-template-columns: min-content 1fr min-content min-content min-content min-content;
grid-auto-rows: min-content;
margin-left: 10px;
grid-column-gap: 20px;
overflow-y: ${props=> props.isCurrent ? '' : 'scroll'};
align-items: center;

`;

export const TableHeader = styled.div`
text-transform: uppercase;
font-size: 10px;
width: min-content;
visibility: ${props => props.visibility ? props.visibility : 'visible'};
margin-bottom: 10px;
`;

export const GridHeader = styled.div`
scroll-behavior: smooth;
display: grid;
grid-row-gap: 10px;
white-space: nowrap;
grid-template-columns: 25px 1fr min-content min-content min-content 30px;
grid-auto-flow: rows;
grid-column-gap: 20px;
margin-right:  ${props=> props.isCurrent ? '0' : '17px'};
margin-left: 10px;

`;

export const GridItem = styled.div`
text-align: ${props=> props.align ? props.align : 'left'};
color: ${props=> props.isSelected ? 'var(--uiollyPurple)' : 'var(--fontColor)'};
`;