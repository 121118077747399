import styled from "styled-components";

export const Wrapper = styled.div`
position: relative;
width: 100%;
height: 100%;
display: flex;
align-items: center;
flex-direction: column;
background: ${props => props.isEmpty ? 'var(--lightestGrey)' : ''};
color: ${props => props.isEmpty ? 'var(--lightGrey)' : ''};
justify-content: ${props => props.isEmpty ? 'center' : 'flex-start'};
>div:not(:first-child){
    margin-top: 20px;
}
`;

export const CollectionTileWrapper = styled.div`
display: grid;
grid-template-areas: 'titleArtist date''cover cover';
grid-template-columns: 1fr 1fr;
user-select: none;
width: 100%;
`;


export const TitleArtist = styled.div`
grid-area: titleArtist;
font-size: 12px;
display: flex;
`;

export const DateSubmitted = styled.div`
grid-area: date;
text-align: right;
font-size: 12px;
display: flex;
justify-content: flex-end;
`;


export const CollectionCoverWrapper = styled.div`
position: relative;
overflow: hidden;
height: 300px;
grid-area: cover;
width: 100%;
display: flex;
    align-items: center;
    justify-content: center;

img{
    position: relative;
    object-fit: cover;
    object-position: center;
    min-width: 100%;
    min-height: 100%;
}
`;