import styled from "styled-components";
export const CheckboxStyle = styled.label`
margin-bottom: 2px;
display: flex;
align-items: center;
justify-content: center;
font-size: var(--fontSmall);
color: var(--purpleGrey);
font-weight: 500;
letter-spacing: 0.02rem;
span{
    margin-left: 5px;
    display: inline-block;
    width: 15px;
    height: 15px;
    background: rgba(255,255,255,0.5);
    position: relative;
    border: 1px solid var(--uiollyPurple);
    box-shadow: 0 0 1px 1px inset var(--uiollyPurple);
    cursor: pointer;
}

strong{
    margin-left: 0.2rem;
    color: #ffffff;
    font-weight: 700;
}

input{
    position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

input:checked ~ span{
    background: rgb(255,255,255);
    box-shadow: 0 0 1px 1px inset #000;
}

input:checked ~ span:after{
    content: "";
    position: relative;
    display: block;
 top: 1px;
  width: 3px;
  height: 8px;
  margin: auto auto;
  border: solid rgb(0,0,0);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
`;