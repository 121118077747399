import styled from 'styled-components';


export const LabelStyled = styled.label`
    position: relative;
    font-size: var(--fontSmall);
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    font-weight: 700;
    display:inline-block;
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0'};

`;

export const DollarSign = styled.div`
user-select: none;
position: absolute;
left: 1rem;
`;


export const InputStyled = styled.input`
  background: none;
  border: 1px solid var(--fontColor);
  color: var(--fontColor);
 
    height: 49px;
    
    font-weight: 600;
   
    padding: ${props => props.name === 'price' ? '0 20px 0 40px' : '0 20px 0 20px'};
    width: 100%;
    position: relative;
    letter-spacing: .1rem;
    

    ::placeholder{
        color: #696980;
    }

    :focus{
        outline: 2px solid rgba(0, 60, 249, .5);
        
        
    }
    /* ::-webkit-inner-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
    }
    ::-webkit-outer-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
    }  

   appearance: textfield;
   -webkit-appearance: textfield;
   -moz-appearance: textfield; */


`;

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
`;

export const InnerWrapper = styled.div`
  position: relative;
    width: 100%;
    display: flex;
    align-items: center;
`;