import React, { useRef } from 'react'

import {
    Wrapper, Image, ImageWrapper, DetailsWrapper, RowWrapper, Video,
    CurrentBidValue, CurrentBidLabel, LastSoldLabel, LastSoldValue, Pricing,
    Timer, Title, Artist, Edition, OuterWrapper, NavLink, EditionLabel
} from './ArtworkCard.styles'

import { VALID_FILE_TYPES } from '../../../config.js'
import { artworkPath, getFileType, removeTrailingZeroes } from '../../../helpers'

const ImageArtwork = ({ image, title }) => {

    return (
        <ImageWrapper>

            <Image src={image} alt={title} />

        </ImageWrapper>
    )
}

const VideoArtwork = ({ artwork, isFeatured }) => {
    const videoRef = useRef(null);
    if (isFeatured && videoRef.current !== null) {
        videoRef.current.play()
    }
    else if (videoRef.current !== null) {
        videoRef.current.pause()
    }
    return (
        <Video
            ref={videoRef}
            src={artwork}
            width="100%"
            autoPlay={false}
            controls={false}
            onMouseOver={(event) => videoRef.current.play()}
            onMouseOut={(event) => videoRef.current.pause()}
            loop={true}
            muted
            playsInline
        />
    )
}


const Details = ({ value }) => {

    const label = value.sale_type.toUpperCase() === 'auction'.toUpperCase() ? 'Current Bid' : 'Ask';
    return (
        <DetailsWrapper>

            <RowWrapper> <Title>{value.title}</Title><Edition>{value.edition} of {value.editionsReleased}</Edition></RowWrapper>

            <Pricing>
                <Artist>{value.artistName}</Artist>
                <CurrentBidLabel>{label}</CurrentBidLabel>
                <CurrentBidValue>{value.price}</CurrentBidValue>
                <LastSoldLabel>Last Sold</LastSoldLabel>
                <LastSoldValue>{value.lastSold}</LastSoldValue>
            </Pricing>
        </DetailsWrapper>
    )
}

const PrimaryMarketDetails = ({ value, collectionState, artist }) => {

    const saleType = value.sale_type;
    // const priceAdj = value.price !== undefined ?
    //     value.price.split('.').length > 1 ?
    //         value.price.split('.')[0] + `${value.price.split('.')[1] !== '00' ? '.' + value.price.split('.')[1] : ''}` :
    //         value.price : undefined;
    const priceAdj = value.price ? removeTrailingZeroes(value.price) : undefined
    const price = value.initialDropPrice !== undefined ? value.initialDropPrice : !priceAdj.includes('$') ? `${priceAdj} Ξ` : `${priceAdj}`;

    const label = saleType.toUpperCase() === 'auction'.toUpperCase() ?
        collectionState === 'pending' ? 'Minimum Bid' : 'Highest Bid' : 'Fixed Price';

    const editionsAvailable = collectionState === 'pending' ? value.max_mint === '1' ? 'Single Edition' : value.max_mint : value.max_mint - value.editionsReleased;
    return (
        <DetailsWrapper>
            <Artist>{artist}</Artist>
            <RowWrapper> <Title>{value.title}</Title></RowWrapper>
            <Pricing>

                <CurrentBidLabel>{label}</CurrentBidLabel>
                <CurrentBidValue>{price}</CurrentBidValue>
                <Edition>{editionsAvailable}</Edition>
                <EditionLabel>Available</EditionLabel>
            </Pricing>

        </DetailsWrapper>
    )
}

const ArtworkCard = ({ market, collectionState, value, artwork, artist, title, editions, editionsForSale, price, timer, saleType, previousSale }) => {
    console.log(artwork)
    
    const type = getFileType(value.path)
    const link = market === 'primary' ? '/productpage/' + value.blockchainAddress + '/' + value.artworkID
        : '/productpage/' + value.blockchainAddress + '/' + value.artworkID + '/' + value.tokenID;
    console.log(type, "type")
    return (
        <NavLink to={link}>
            <OuterWrapper>
                <Wrapper>
                    {type === 'image' && <ImageArtwork image={value.path} title={title} />}
                    {type === 'video' && <VideoArtwork artwork={value.path} title={title} />}
                    {
                        market === 'primary' && <PrimaryMarketDetails value={value} collectionState={collectionState} artist={artist} />
                    }
                    {
                        market !== 'primary' && <Details value={value} artist={artist} />
                    }
                </Wrapper>
            </OuterWrapper>
        </NavLink>
    )



}

export default ArtworkCard
