import axios from 'axios'

const apiSettings = { //http://426xz11dbtest123.testdomain415.site/nftapi/assets/uploads/4ada156e04aa8e003978cfb27f3fa2a7.png
    fetchCurrentUserAdditionalDetails: async (email) => {
        let res = await axios({
            method: 'get',
            url: `https://api.38mm88.testdomain415.site/get_current_admin?email=${email}`
        });
        return res.data.userDetails
    },
    submitCollection: async (collection) => {
        const formData = new FormData();
        formData.append("collection-items", JSON.stringify(collection.collectionArtworks));
        collection.collectionArtworks.forEach((artwork, index) => {
            formData.append('artwork-' + index, artwork.file);
        });
        formData.append("collection-title", collection.collectionTitle);
        formData.append("artist", "Darlene");
        formData.append("email", "email@email.com");
        formData.append("collection-description", collection.collectionDescription);
        formData.append("collection-cover-art", collection.collectionCoverArt);
        const res = await axios({
            method: 'post',
            url: 'https://api.38mm88.testdomain415.site/save_collection',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData
        })
        return res
    },
    getCollectionsWithStatus: async (collectionStatus) => {
        let res = await axios({
            method: 'get',
            url: `https://api.38mm88.testdomain415.site/get_collections?collection_status=${collectionStatus}`
        });
        return res.data["collections"] || []
    },
    getScheduledCollectionsRanged: async (range) => {
        const formData = new FormData();
        formData.append("range", JSON.stringify(range));
        let res = await axios({
            method: 'post', // & controllers
            url: 'https://api.38mm88.testdomain415.site/get_collections_range', //api/config/database.php
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: formData
        });

        return res.data["collections"]
    },
    getCollection: async (collectionID) => {
        let res = await axios({
            method: 'get',
            url: `https://api.38mm88.testdomain415.site/get_collection?collection_id=${collectionID}`
        });
        return res.data
    },
    setAccessToken: async (email, token) => {
        const qs = require('qs');
        const data = qs.stringify({
            'email': email,
            'token': token,
            'admin': 1
        });
        let res = await axios({
            method: 'post', // & controllers
            url: 'https://api.38mm88.testdomain415.site/set_access_token', //api/config/database.php
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        });

        return res
    },
    processCollection: async (collection_id, status) => {
        if (status === 1 || status === 0) {
            var qs = require('qs');
            var data = qs.stringify({
                'collection_id': collection_id,
                'status': status
            });
            let res = await axios({
                method: 'post',
                url: 'https://api.38mm88.testdomain415.site/update_collection_status',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: data

            });
            return res.data
        }
        else {
            return "error"
        }

    },
    rejectArtist: async (artist_id, collection_id, status) => {
        var qs = require('qs');
        var data = qs.stringify({
            'artist_id': artist_id,
            'collection_id': collection_id,
            'status': status
        });
        let res = await axios({
            method: 'post',
            url: 'https://426xz11dbtest123.testdomain415.site/api/index.php/home/artist_status_update',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        });
        return res
    },
    fetchCollectionStatuses: async () => {

        let res = await axios({
            method: 'get',
            url: `https://api.38mm88.testdomain415.site/get_collection_status_keys`
        });
        return res.data.valid_statuses
    },
    scheduleCollection: async (accessToken, admin_email, collectionID, release_date, end_date) => {
        let res = await axios({
            method: 'get',
            url: `https://api.38mm88.testdomain415.site/schedule_collection?access_token=${accessToken}&admin_email=${admin_email}&collection_id=${collectionID}&release_date=${release_date}&end_date=${end_date}`
        });
        return res.data
    },
    getArtists: async () => {
        let res = await axios({
            method: 'get',
            url: `https://api.38mm88.testdomain415.site/get_featured_artist_options`
        });
        return res.data.artists
    },
    getFeaturedArtists: async () => {
        let res = await axios({
            method: 'get',
            url: `https://api.38mm88.testdomain415.site/curr_featured_artists`
        });
        return res.data.artists
    },
    updateFeaturedArtists: async (update) => {
        const formData = new FormData();

        if (Object.keys(update).includes("add")) {
            formData.append("add-artists", JSON.stringify(update.add));
        }
        if (Object.keys(update).includes("remove")) {
            formData.append("remove-artists", JSON.stringify(update.remove));
        }

        const res = await axios({
            method: 'post',
            url: 'https://api.38mm88.testdomain415.site/update_featured_artists',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData
        })
        return res
    },
}

export default apiSettings;