import styled from "styled-components";

export const Wrapper = styled.div`
height: 30px;
width: 30px;
display: flex;
justify-content: center;
align-items: center;
img{
    height: 25px;
    width: 25px;
    transform: rotate(45deg);
}
`;