import React, { useEffect } from 'react'
import dayjs from 'dayjs'
import {
    Wrapper,
    SideBarSectionHeader,
    SideBarTitle,
    SideBarPadding,
    Spacer,
    ArrowButton,
    NavLink,
    CollectionTileWrapper
} from './SideBar.styles'
import CreateEventButton from '../CreateEventButton'
import NavArrow from '../../../images/formNavArrow.svg'
import { useCollections } from '../../../contexts/CollectionsContext'
import { useCalendar } from '../../../contexts/CalendarContext'
import CollectionTile from '../CollectionTile'
import { useNavigate } from 'react-router-dom'
import API from '../../../API'

const SideBar = () => {
    const navigate = useNavigate()
    const { fetchCollections,
        collections,
        onSelectionCollection,
        collection, setCollection } = useCollections();
    const { monthUnixRange, monthIndex, scheduledCollections } = useCalendar()

    return (
        <Wrapper>
            <NavLink
            onClick={()=>{
                //navigate('/collections/unscheduled', { state: { name: state.name, email: state.email } })
                setCollection(null)
                navigate('/collections/unscheduled')
            }}
            // to={
            //     // collection !== null ?
            //     // { pathname: `/collections/unscheduled/${collection.collection_id}`, state: { collection: collection } } :
            //     { pathname: `/collections/unscheduled`, state: { collection: collection } }
            // }
            ><ArrowButton><img src={NavArrow} /></ArrowButton>Admin</NavLink>
            {(collection !== null && collections.length > 0) && <SideBarTitle>Unscheduled Collections</SideBarTitle>}
            <SideBarPadding>
                {
                    collection !== null && collections.length > 0 && collections.map((c) => (
                        <CollectionTile
                            sidebar
                            isSelected={c.collection_id === collection.collection_id}
                            collection={c}
                            key={c.collection_id} />))
                }
            </SideBarPadding>
            <Spacer><SideBarTitle>Scheduled Collections - {dayjs(new Date(dayjs().year(), monthIndex)).format('MMMM YYYY')}</SideBarTitle></Spacer>
            <SideBarPadding>
                {
                    scheduledCollections.length > 0 && scheduledCollections.map((c) => (
                        <CollectionTile
                            sidebar
                            isSelected={true}
                            collection={c}
                            key={c.collection_id} />))
                }
            </SideBarPadding>

        </Wrapper>
    )
}

export default SideBar
