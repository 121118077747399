import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import {
    Wrapper,
    Paragraph,
    Header,
    EventBubble,
    CoverArt,
    Button
} from './Day.styles'
import CreateEventButton from '../CreateEventButton'
import { useCalendar } from '../../../contexts/CalendarContext'
import { formatTimeZone } from '../../../utils'

import CollectionTile from '../CollectionTile'

const Day = ({ day, rowIdx }) => {
    const { selectedDay, selectedTime, setSelectedDay, showEventModal, setShowEventModal, closeEventModal, savedEvents } = useCalendar();
    const [dayEvents, setDayEvents] = useState([])

    useEffect(() => {
        const events = savedEvents.filter(evt => dayjs.unix(evt.dateAndTime).format('DD-MM-YY') === day.format('DD-MM-YY'))
        setDayEvents(events)
    }, [savedEvents,day])
    const getDayClass = (day) => {
        const format = "YYYY-MM-DD"
        return day.format(format);
    }
    const isToday = () => {
        return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY")
    }

    const isSelected = () => {
        if (selectedDay === null) return false
        return day.format("DD-MM-YY") === selectedDay.format("DD-MM-YY")
    }
    const isPast = () => {
        // return day.valueOf() < dayjs().subtract(1, 'day').valueOf()
        return day.valueOf() < dayjs().valueOf()//.subtract(1, 'day').valueOf()
    }

    const onClick = () => {
        if (!isPast()) {
            setSelectedDay(day)
            if (selectedTime.split(':').length > 1)
                setSelectedDay(prev => prev.hour(selectedTime.split(':')[0]).minute(selectedTime.split(':')[1]))
            setShowEventModal(true)
        }
    }


    return (
        <Wrapper isToday={isToday()} onClick={() => onClick()} isSelected={isSelected()} isPast={isPast()}>
            <Header >
                {isToday() && <Paragraph>Today</Paragraph>}
                {rowIdx === 0 && <Paragraph> {day.format('ddd').toUpperCase()}</Paragraph>}
                <Paragraph > {day.format('DD')}</Paragraph>
            </Header>
            {dayEvents.length > 0 && dayEvents.map((event, i) => {
                const localTimeZoneAbbrev = formatTimeZone((dayjs().utcOffset() / 60).toString())
                return (
                    <CollectionTile 
                        collection={event}
                        key={i}
                        releaseDate={`${dayjs.unix(event.dateAndTime).format('hh:mm a')} ${localTimeZoneAbbrev}`}/>
                    // <EventBubble key={i} backgroundIMG={event.cover_art}>
                    //     <CoverArt backgroundIMG={event.cover_art} />
                    //     <div>{event.title}</div>
                    //     <div>{`${dayjs(new Date(event.dateAndTime)).format('hh:mm a')} ${localTimeZoneAbbrev}`}</div>
                    // </EventBubble>
                )
            })}
            {/* <Button className={"addEventButton"}>{!isPast() && <CreateEventButton />}</Button> */}
        </Wrapper>
    )
}

export default Day
