import React from 'react'

import { Wrapper } from './Month.styles'
import { useCalendar } from '../../../contexts/CalendarContext'
import Day from '../Day'

const Month = ({ month }) => {
    const { selectedDay, setSelectedDay, monthUnixRange } = useCalendar();

    return (
        <Wrapper>
            {
                month.map((row, i) => (
                    <React.Fragment key={i} >
                        {row.map((day, idx) => (
                            <Day day={day} key={idx} rowIdx={i} />
                        ))}
                    </React.Fragment >
                ))
            }
        </Wrapper >
    )
}

export default Month
