import React, { useContext, useState, useEffect } from 'react';
import API from '../API'
const CollectionsContext = React.createContext()

export const useCollections = () => {
    return useContext(CollectionsContext)
}

export const CollectionsProvider = ({ children }) => {
    const [collections, setCollections] = useState([])
    const [selectedCollection, setSelectedCollection] = useState(null)
    const [collection, setCollection] = useState(null)

    const fetchCollections = async (filter) => {
        try {
            const pendingCollections = await API.getCollectionsWithStatus(filter)
            setCollections(pendingCollections)
            return pendingCollections
            // if (parseInt(filter) === 2) {
            //     setCollection(pendingCollections.length ? pendingCollections[0] : null)
            // }
        } catch (e) {
            console.log(e)
            return `${e.code}`
        }
    }
    const onSelectionCollection = async (collectionID) => {
        try {
            const pendingCollection = await API.getCollection(collectionID)
            setCollection(pendingCollection)
            return pendingCollection

        } catch (e) {
            return e
        }
    }
    const removeSelection = () => {
        setCollection(null)
    }
    const value = {
        collections,
        fetchCollections,
        onSelectionCollection,
        collection,
        setCollection,
        removeSelection
    }
    return (
        <CollectionsContext.Provider value={value}>
            {children}
        </CollectionsContext.Provider>
    )
}