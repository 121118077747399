import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    position: relative;
    overflow: hidden;
`;
export const SideBar = styled.div`
    user-select: none;
    text-transform: uppercase;
    letter-spacing: 0.03rem;
    border-right: var(--sideBarBorder);
    justify-content: flex-start;
    width: 30%;
    max-width: 250px;
    height: 100%;
    position: relative;
    padding: 40px 0px 20px;
    padding: 0 0px 20px;
    background: var(--sideBarBackground);
`;

export const SideBarLink = styled.div`
    padding: 10px 20px;
    font-size: 12px;
    background: ${props => props.isActive ? 'var(--backgroundActiveLi)' : 'transparent'};
    border-right: ${props => props.isActive ? 'var(--borderRightActiveLi)' : 'none'};
`;

export const MessagesLink = styled(SideBarLink)`
    font-size: 15px;
    font-weight: 500;
`;

export const SideBarLinkHeader = styled.div`
    padding: 10px 20px;
    font-weight: 500;
    font-size: 15px;
`;

export const Spacer = styled.div`
margin-top: 30px;
`;

export const RightContainer = styled.div`
    width: 100%;
    position: relative;
    height: 100vh;
    overflow: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    display: grid;
    grid-template-rows: min-content 1fr min-content;
`;

export const Row = styled.div`
display: flex;
width: 100%;
align-items: center;
height: 60px;
justify-content: space-between;
user-select: none;
padding: ${props => props.isMessages ? '0 20px' : '0 50px'};
margin-bottom: 10px;
text-transform: uppercase;

div{
    display: flex;
}
>div>div:not(:first-child){
    margin-left: 0.5rem;
}
`;

export const Innercontainer = styled.div`
width: 100%;
height: 100%;
overflow-x: hidden;
padding: ${props => props.isMessages ? '0 20px' : '0 50px'};
position: relative;
`;

export const ScrollContainer = styled.div`
    width: 100%;
    position: relative;
    height: 100%;
    overflow: auto;
    scroll-behavior: smooth;
    
    display: flex;
    flex-direction: column;
`;

export const DateWrapper = styled.div`
font-size: 10px;
display: flex;
align-items: flex-end;
white-space: nowrap;
`;

export const SiteHeader = styled.div`
    padding: 0 20px ;
    height: 60px;
    display: flex;
    position: relative;
    align-items: center;
    text-transform: uppercase;
`;