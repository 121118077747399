import React from 'react'
import { useCalendar } from '../../../contexts/CalendarContext'
import dayjs from 'dayjs'
import {
    Wrapper,
    NavButton,
    TodayButton,
    Month
} from './CalendarHeader.styles'

import NavArrow from '../../../images/formNavArrow.svg'

const CalendarHeader = () => {

    const { monthIndex, setMonthIndex } = useCalendar();
    return (
        <Wrapper>

            <Month>{dayjs(new Date(dayjs().year(), monthIndex)).format('MMMM YYYY')}</Month>
            <TodayButton onClick={() => { setMonthIndex(dayjs().month()) }}>Today</TodayButton>
            <NavButton direction={"left"} onClick={() => { setMonthIndex(prev => (prev - 1)) }}><img src={NavArrow} /></NavButton>
            <NavButton direction={"right"} onClick={() => { setMonthIndex(prev => (prev + 1)) }}><img src={NavArrow} /></NavButton>
            <h1>Drop Calendar</h1>
        </Wrapper>
    )
}

export default CalendarHeader
