import React, { useState, useEffect } from 'react';
import API from '../../../API'
import {
    Wrapper,
    ScrollContent,
    ScrollWrapper,
    Header,
    ArtistWrapper,
    ProfilePicture,
    ActiveArtistSection,
    TableHeader,
    AddArtistWrapper,
    GridItem
} from './FeaturedArtists.styles'
import CurrentFeatures from './CurrentFeatures'
import ProcessArtistModal from './ProcessArtistModal'
import AddIcon from '../../../images/addIcon.svg'
import RejectIcon from '../../../images/rejectIcon.svg'

import Checkbox from '../../Checkbox';
import Button from '../../Button';

const AddArtist = ({ onClick }) => (
    <AddArtistWrapper onClick={onClick}>
        <img src={AddIcon} alt="add-artist" />
    </AddArtistWrapper>
)

const FeaturedArtists = () => {
    const [currentlyFeatured, setCurrentlyFeatured] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [processArtists, setProcessArtists] = useState({ removeArtists: [], addArtists: [] })
    // artists with a status >= 4 (upcoming, live, or past collections avail on the site)
    const [activeArtists, setActiveArtists] = useState([]);
    const [selArtist, setSelArtist] = useState({ artist: { artist_id: '' }, action: '' });
    const [selectedArtists, setSelectedArtists] = useState({ removeArtists: [], addArtists: [] });
    const loadContent = () => {
        API.getFeaturedArtists().then((res2) => {
            if (res2 !== null)
                setCurrentlyFeatured(res2)
            else{
                setCurrentlyFeatured([])
            }
            API.getArtists().then((res) => {
                if (res2 !== null)
                    setActiveArtists(res.filter(a => !res2.map(f => f.artist_id).includes(a.artist_id)))
                else
                    setActiveArtists(res)
            })
            setLoading(false)
        })
    }

    useEffect(() => {
        setLoading(true)
        loadContent()
    }, [])

    const handleChange = () => {
        const payload = new Object();
        if (selectedArtists.addArtists.length)
            payload['add'] = selectedArtists.addArtists.map(it => it.artist_id)
        if (selectedArtists.removeArtists.length)
            payload['remove'] = selectedArtists.removeArtists.map(it => it.artist_id)
        console.log("payload", payload)
        API.updateFeaturedArtists(payload).then(res => {
            setShowModal(false)
            setSelArtist({ artist: { artist_id: '' }, action: '' })
            setSelectedArtists({ removeArtists: [], addArtists: [] })
            loadContent()
        }).catch(e => {
            console.log(e)
        })
    }


    return (
        <>

            <Wrapper>
                <GridItem gridCol="1 / span 2">
                    <Button
                        background="var(--uiollyPurple)"
                        disabled={!selectedArtists.addArtists.length && !selectedArtists.removeArtists.length}
                        onClick={handleChange}
                        validContinue={selectedArtists.addArtists.length || selectedArtists.removeArtists.length}
                        text="Save Changes"
                        width="300px" /></GridItem>
                <ActiveArtistSection>
                    {/* <CurrentFeatures currentlyFeatured={currentlyFeatured} /> */}
                    <h4>Currently Featured</h4>
                    <p>You can feature up to three artists on the landing page.</p>
                    <Header isCurrent>
                        <TableHeader></TableHeader>
                        <TableHeader></TableHeader>
                        <GridItem gridCol="3 / span 3" ><TableHeader align="center">Collections</TableHeader></GridItem>
                        <TableHeader></TableHeader>
                        <TableHeader></TableHeader>
                        <TableHeader>Name</TableHeader>
                        <TableHeader>Live</TableHeader>
                        <TableHeader>Past</TableHeader>
                        <TableHeader>Future</TableHeader>
                        <TableHeader>Move</TableHeader>
                    </Header>
                    <ScrollWrapper>
                        <ScrollContent isCurrent>
                            <TableHeader visibility="hidden"></TableHeader>
                            <TableHeader visibility="hidden">Name</TableHeader>
                            <TableHeader visibility="hidden">Live</TableHeader>
                            <TableHeader visibility="hidden">Past</TableHeader>
                            <TableHeader visibility="hidden">Future</TableHeader>
                            <TableHeader visibility="hidden">Move</TableHeader>
                            {
                                currentlyFeatured.map(artist => (
                                    // <ArtistWrapper key={artist.id}>
                                    <>
                                        <ProfilePicture><img src={artist.profile_pic} /></ProfilePicture>
                                        <GridItem isSelected={selArtist.artist.artist_id === artist.artist_id}>{artist.artist_name}</GridItem>
                                        <GridItem isSelected={selArtist.artist.artist_id === artist.artist_id} align="center">{artist.num_c_live}</GridItem>
                                        <GridItem isSelected={selArtist.artist.artist_id === artist.artist_id} align="center">{artist.num_c_past}</GridItem>
                                        <GridItem isSelected={selArtist.artist.artist_id === artist.artist_id} align="center">{artist.num_c_future}</GridItem>
                                        <Checkbox
                                            isCheckedX={selectedArtists.removeArtists.filter(it => it.artist_id === artist.artist_id).length}
                                            onCheck={() => {
                                                if (!selectedArtists.removeArtists.filter(it => it.artist_id === artist.artist_id).length)
                                                    setSelectedArtists(prev => ({ ...prev, removeArtists: [...prev.removeArtists, artist] }))
                                                else {
                                                    setSelectedArtists(prev => ({ ...prev, removeArtists: [...prev.removeArtists.filter(it => it.artist_id !== artist.artist_id)] }))
                                                    if (selectedArtists.addArtists.length >= selectedArtists.removeArtists.length)
                                                        setSelectedArtists(prev => ({ ...prev, addArtists: [...prev.addArtists.splice(-1)] }))
                                                }

                                            }} />
                                        {/* <AddArtist
                                            onClick={() => {
                                                setSelArtist({ artist: artist, action: 'add' })
                                                setShowModal(true)
                                            }}
                                        ></AddArtist> */}
                                    </>
                                    // </ArtistWrapper>
                                ))
                            }
                        </ScrollContent>
                    </ScrollWrapper>
                </ActiveArtistSection>
                <ActiveArtistSection>
                    <h4>All Active Artists</h4>
                    <p>Artists with collections pending, live, or sold, excluding those currently featured.</p>
                    <Header>
                        <TableHeader></TableHeader>
                        <TableHeader></TableHeader>
                        <GridItem gridCol="3 / span 3" ><TableHeader align="center">Collections</TableHeader></GridItem>
                        <TableHeader></TableHeader>
                        <TableHeader></TableHeader>
                        <TableHeader>Name</TableHeader>
                        <TableHeader>Live</TableHeader>
                        <TableHeader>Past</TableHeader>
                        <TableHeader>Future</TableHeader>
                        <TableHeader>Move</TableHeader>
                    </Header>
                    <ScrollWrapper>
                        <ScrollContent>
                            <TableHeader visibility="hidden"></TableHeader>
                            <TableHeader visibility="hidden">Name</TableHeader>
                            <TableHeader visibility="hidden">Live</TableHeader>
                            <TableHeader visibility="hidden">Past</TableHeader>
                            <TableHeader visibility="hidden">Future</TableHeader>
                            <TableHeader visibility="hidden">Move</TableHeader>
                            {
                                activeArtists.map(artist => (
                                    // <ArtistWrapper key={artist.id}>
                                    <>
                                        <ProfilePicture><img src={artist.profile_pic} /></ProfilePicture>
                                        <GridItem isSelected={selArtist.artist.artist_id === artist.artist_id}>{artist.artist_name}</GridItem>
                                        <GridItem isSelected={selArtist.artist.artist_id === artist.artist_id} align="center">{artist.num_c_live}</GridItem>
                                        <GridItem isSelected={selArtist.artist.artist_id === artist.artist_id} align="center">{artist.num_c_past}</GridItem>
                                        <GridItem isSelected={selArtist.artist.artist_id === artist.artist_id} align="center">{artist.num_c_future}</GridItem>
                                        <Checkbox
                                            isDisabled={(currentlyFeatured.length - selectedArtists.removeArtists.length + selectedArtists.addArtists.length >= 3)}
                                            isCheckedX={selectedArtists.addArtists.filter(it => it.artist_id === artist.artist_id).length}
                                            onCheck={() => {
                                                if (!selectedArtists.addArtists.filter(it => it.artist_id === artist.artist_id).length)
                                                    setSelectedArtists(prev => ({ ...prev, addArtists: [...prev.addArtists, artist] }))
                                                else
                                                    setSelectedArtists(prev => ({ ...prev, addArtists: [...prev.addArtists.filter(it => it.artist_id !== artist.artist_id)] }))
                                                console.log(selectedArtists)
                                            }} />
                                        {/* <AddArtist
                                            onClick={() => {
                                                setSelArtist({ artist: artist, action: 'add' })
                                                setShowModal(true)
                                            }}
                                        ></AddArtist> */}
                                    </>
                                    // </ArtistWrapper>
                                ))
                            }
                        </ScrollContent>
                    </ScrollWrapper>
                </ActiveArtistSection>

            </Wrapper>
            {
                showModal && <ProcessArtistModal
                    setShowModal={setShowModal}
                    onClose={() => { setSelArtist({ artist: { artist_id: '' }, action: '' }) }}
                    showModal={showModal}
                    setSelArtist={setSelArtist}
                    setSelectedArtists={setSelectedArtists}
                    selectedArtists={selectedArtists}
                    activeArtists={activeArtists}
                    currentlyFeatured={currentlyFeatured}
                    selectedArtist={selArtist}
                    handleChange={handleChange}
                    type={selArtist.action}
                    loading={loading}
                />
            }


        </>);
};

export default FeaturedArtists;
