import styled from "styled-components";

export const ModalWrapper = styled.div`
position: absolute;
background: var(--background);
display: grid;
justify-content: center;
align-items: center;
box-shadow: 0 0 20px rgba(0,0,0,0.5);
min-width: 500px;
grid-template-columns: 1fr min-content;
grid-column-gap: 10px;
grid-template-areas: 'title close''subtext subtext''content content''button button';
padding: 20px;
grid-row-gap: 20px;
z-index: 3;
`;

export const ModalOverlay = styled.div`
visibility: ${props => props.showModal ? 'visible' : 'hidden'};
position: fixed;
z-index: 2;
display: flex;
align-items: center;
justify-content: center;
width: 100vw;
height: 100vh;
left: 0;
top: 0;
background: transparent;
`;

export const CloseButtonContainer = styled.div`
display: flex;
width: 100 %;
justify-content: flex-end;
position: relative;
img{
    height: 20px;
    width: 20px;
    cursor: pointer;
}
`;

export const GridItem = styled.div`
grid-area: ${props => props.gridArea ? props.gridArea : ''};

`;

export const SubText = styled.div`
font-size: ${props => props.size ? props.size : '12px'};
margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0'};
user-select: none;
color: ${props => props.color ? props.color : 'var(--purpleGrey)'};
`;

export const ModalTitle = styled.div`
user-select: none;
text-transform: uppercase;
`;
