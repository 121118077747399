import styled from "styled-components";
import { NavLink as Link } from 'react-router-dom'



export const NavLink = styled.div`

`;

export const OuterWrapper = styled.div`
padding: 15px;
`;

export const Wrapper = styled.div`
    height: var(--artworkCardHeight);
    width: var(--artworkCardWidth);
    max-width: 240px;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    outline: var(--artworkCardBorder);
    user-select: none;
`;

export const ImageWrapper = styled.div`
    width: var(--artworkCardImageSize);
    height: var(--artworkCardImageSize);
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--artworkCardBorderRadius);
`;

export const Image = styled.img`
    object-fit: cover;
    position: relative;
    min-height: 100%;
    min-width: 100%;
    border-radius: var(--artworkCardBorderRadius);
`;

export const DetailsWrapper = styled.div`
    padding: var(--artworkCardDetailsPadding);
`;

export const Timer = styled.div`
display: flex;
font-size: 10px;
justify-content: flex-end;
align-items: center;
margin-top: 0.2rem;
width: 100%;
height: 1rem;
position: relative;
img{
    margin-left: 5px;
    height: 12px;
    width: 12px;
    
}
`;

export const Title = styled.div`
font-size: 14px;
font-weight: 600;
max-width: 80%;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
grid-area: title;
`;

export const Artist = styled.div`
font-size: 9px;
`;

export const Edition = styled.div`
grid-area: edition;
font-size: var(--artworkCardEditionFontSize);
color: var(--artworkCardEditionFontColor);
text-align: right;
`;

export const Pricing = styled.div`
align-items: end;
margin-top: 0.5rem;
font-size: var(--artworkCardPriceFontSize);
display: grid;
grid-template-columns: 1fr 1fr;
grid-template-rows: auto auto auto;
grid-template-areas: 
    'title title'
    'CurrentBidValue edition'
    'CurrentBidLabel editionLabel';
`;


export const CurrentBidValue = styled.div`
    grid-area: CurrentBidValue;
    font-size: var(--artworkCardPriceFontSize);
    text-align: left;
    color: var(--artworkCardPriceFontColor);
`;

export const EditionLabel = styled.div`
    grid-area: editionLabel;
    text-transform: uppercase;
    font-size: 10px;
    text-align: right;
    color: var(--artworkCardEditionFontColor);
`;

export const CurrentBidLabel = styled.div`
    grid-area: CurrentBidLabel;
    font-size: 10px;
    text-transform: uppercase;
    text-align: left;
`;

export const LastSoldValue = styled.div`
    grid-area: LastSoldValue;
    font-size: 12px;
    text-align: right;
`;

export const LastSoldLabel = styled.div`
    grid-area: LastSoldLabel;
    font-size: 10px;
    text-align: right;
    text-transform: uppercase;
`;



export const Video = styled.video`
    min-width: var(--artworkCardImageSize);
    min-height: var(--artworkCardImageSize);
    object-fit: cover;


`;

export const RowWrapper = styled.div`
display: flex;
position: relative;
width: 100%;
align-items: flex-end;
justify-content: space-between;
`;