import styled from "styled-components";

export const Wrapper = styled.div`
align-items: center;
position: relative;
outline: 1px solid #c2c2c2;
box-sizing: border-box;
display: flex;
flex-grow: 1;
flex-direction: column;
height: 100%;
width: 100%;
padding: 5px; ///
background: #dedede;
background: ${props => props.isToday ? 'var(--uiollyPurple)' :
        props.isPast ? "repeating-linear-gradient(-45deg,#ededed,#ededed 5px,#ffffff 5px,#ffffff 10px)" : props.isSelected ? '#ffb375' : '#ffffff'};
.addEventButton{
        visibility: hidden;
    }
    :hover .addEventButton{
        visibility: visible;
    }
`;

export const Paragraph = styled.div`
    font-size: 12px;
    padding: 5px;
    text-align: center;
    user-select: none;
    border-radius: 50%;
    
`;

export const Header = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

`;

export const EventBubble = styled.div`
    /* background-image: ${props => props.backgroundIMG ? `url(${props.backgroundIMG})` : '#ffb375'}; */
    background: #ffb375;
    border: 1px solid #000000;
    background-position: center;
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
    border-radius: 5px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 5px 10px;
    user-select: none;
    font-size: 10px;

    margin-top: 5px;
`;

export const Button = styled.div`
position: absolute;
bottom: 0;
/* visibility: ${props => props.isVisible ? 'visible' : 'hidden'}; */
`;

export const CoverArt = styled.div`
position: relative;
background-image: ${props => props.backgroundIMG ? `url(${props.backgroundIMG})` : '#ffb375'};
overflow: hidden;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border: 1px solid #000000;
height: 25px;
width: 25px;
border-radius: 50%;
`;