import React, { useEffect, useState, useRef } from 'react'
import dayjs from 'dayjs'
import { Wrapper, CollectionTileWrapper, TitleArtist, CollectionCoverWrapper, DateSubmitted } from './Collections.styles'

import API from '../../../API';
import { returnMonth, formattedDateAndTime } from '../../../helpers'
import { useNavigate, useParams, useLocation } from 'react-router-dom'

// Hook
import { useCollectionSelectionHook } from '../../../hooks/useCollectionSelectionHook';

//Context
import { useCollections } from '../../../contexts/CollectionsContext'

import {
    NEW_ARTISTS,
    ACCEPTED_ARTISTS,
    NEW_COLLECTIONS,
    SCHEDULED_COLLECTIONS,
    UNSCHEDULED_COLLECTIONS,
    PAST_COLLECTIONS
} from '../../../config'

import CollectionPreview from '../CollectionPreview';

const CollectionTile = ({ collection, onClick }) => {
    const coverArtPath = collection.cover_path.split('./').length > 1 ? collection.cover_path.split('./')[1] : collection.cover_path.split('./')[0];
    const path = collection.cover_path;//`https://426xz11dbtest123.testdomain415.site/nftapi/assets/${coverArtPath}`;
    const dateSubmitted = collection.status >= 3 ? dayjs.unix(collection.release_date).format('MMM D h:mm A') : formattedDateAndTime(collection.created_at)
    return (
        <CollectionTileWrapper onClick={onClick}>
            <TitleArtist>{collection.title} by {collection.first_name} {collection.last_name}</TitleArtist>
            <DateSubmitted>{dateSubmitted}</DateSubmitted>
            <CollectionCoverWrapper>
                <img src={path} alt={collection.title} />
            </CollectionCoverWrapper>
        </CollectionTileWrapper>
    )
}

const Collections = ({ setBreadcrumb, breadcrumb, setItem, filter, collectionStatusKey }) => {
    const { fetchCollections, collections, onSelectionCollection, collection, setCollection, removeSelection } = useCollections();
    const navigate = useNavigate()
    const location = useLocation()
    const { selectedCollection } = useParams();
    const initial = useRef(true)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (initial.current) {
            initial.current = false;
            return;
        }
        if (breadcrumb === null) {
            setCollection(null)
        }
    }, [breadcrumb])

    const selectCollection = async (collectionID) => {
        console.log("selecting collection")
        try {
            setError(false);
            setLoading(true);
            const pendingCollection = await onSelectionCollection(collectionID)
            setItem(pendingCollection)
            setBreadcrumb(filter.type === "Artists" ? pendingCollection.artist.name : pendingCollection.name)
            const potential_path = `/${filter.text.toLowerCase()}/${collectionID}`
            //if (potential_path !== location.pathname && filter.text.toLowerCase() === location.pathname.split('/')[1])
            navigate(potential_path)
        } catch (e) {
            setError(true);
        }
        setLoading(false)
    }

    useEffect(() => {
        if (location.pathname.split('/').length >= 3 && selectedCollection !== undefined) {
            selectCollection(selectedCollection)
        }
    }, [location])
    useEffect(() => {
        fetchCollections(filter.value)
    }, [filter])
    console.log("x", "breadcrumb", breadcrumb, location.pathname.split('/').length, collection, selectedCollection)
    if (loading || collections === undefined) {
        return <Wrapper></Wrapper>
    }
    else if (collections.length === 0) {
        return <Wrapper isEmpty><h1>NO {filter.text} COLLECTIONS</h1></Wrapper>
    }
    else if (collection === null && selectedCollection === undefined) {
        return (
            <Wrapper>
                {collections.map((collection) => (
                    <CollectionTile
                        key={collection.collection_id}
                        onClick={() => {
                            console.log(collection.status)
                            if (parseInt(collection.status) < 3 ||
                                parseInt(collection.status) === 3 && dayjs().unix() >= parseInt(collection.release_date !== null ? collection.release_date : 0))
                                selectCollection(collection.collection_id)
                            else if (parseInt(collection.status) >= 4) {
                                window.open(`https://38mm88.testdomain415.site/collections/${collection.contract_address}`,'_newtab')
                            }
                        }}
                        collection={collection}></CollectionTile>
                ))}
            </Wrapper>
        )
    }
    else {
        return (
            <Wrapper>
                <CollectionPreview collection={collection}></CollectionPreview>
            </Wrapper>
        )
    }
}

export default Collections
