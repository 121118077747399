import React from 'react'

import {
    EventBubble,
    CoverArt,
    Wrapper,
    ReleaseDate
} from './CollectionTile.Styles'

const CollectionTile = ({ sidebar, isSelected, collection, releaseDate, onClick }) => {
    return (
        <Wrapper onClick={onClick}>
            {releaseDate && <ReleaseDate>{releaseDate}</ReleaseDate>}
            <EventBubble
                isSelected={sidebar !== undefined ? isSelected : true}
                backgroundIMG={collection.cover_art !== undefined ? collection.cover_art : collection.cover_path}>
                <CoverArt backgroundIMG={collection.cover_art !== undefined ? collection.cover_art : collection.cover_path} />
                <div>{collection.title}</div>
                
            </EventBubble>
        </Wrapper>
    )
}

export default CollectionTile
