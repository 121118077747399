import styled from "styled-components";

export const Wrapper = styled.div`
height: 100%;
flex-grow: 1;
display: grid;
grid-template-columns: repeat(7,1fr);
grid-template-rows: repeat(5,1fr);
`;

