import React, { useState } from 'react'

import AcceptIcon from '../../../images/acceptIcon.svg'
import RejectIcon from '../../../images/rejectIcon.svg'
import Close from '../../../images/close.svg'

import {
    Wrapper,
    RejectButtonContainer,
    AcceptButtonContainer,
    ScheduleButton,
    DetailsWrapper,
    CloseButtonContainer,
    PopupWrapperOverlay,
    PopupWrapper,
    ButtonContainer,
    Subtext,
    ValueHighlighted,
    Strong,
    Filter,
    Title,
    AcceptRejectWrapper

} from './ProcessPendingBar.styles'

import {
    NEW_ARTISTS,
    ACCEPTED_ARTISTS,
    NEW_COLLECTIONS,
    SCHEDULED_COLLECTIONS,
    UNSCHEDULED_COLLECTIONS,
    PAST_COLLECTIONS,
    ACCEPT,
    REJECT,
    PENDING,
    UNSCHEDULED,
    RESCHEDULE_REQUIRED,
    UNMINTED,
    UPCOMING,
    LIVE,
    PAST
} from '../../../config'

import API from '../../../API';
import { useImportCollectionStatus } from '../../../contexts/CollectionStatusContext'

import Button from '../../Button'
import { useNavigate } from 'react-router-dom'

const AcceptButton = ({ onClick }) => (
    <AcceptButtonContainer onClick={onClick}>
        <img src={AcceptIcon} alt="accept-button" />
    </AcceptButtonContainer>
)

const RejectButton = ({ onClick }) => (
    <RejectButtonContainer onClick={onClick}>
        <img src={RejectIcon} alt="reject-button" />
    </RejectButtonContainer>
)

const ClosePopup = ({ closePopup }) => (
    <CloseButtonContainer >
        <img src={Close} alt="close-popup" onClick={closePopup} />
    </CloseButtonContainer>
)

const Popup = ({ action, visible, type, value, closePopup, handleAssignment, filter }) => {
    // const subtext = filter === NEW_ARTISTS ? ' this artist and their collection' : filter === NEW_COLLECTIONS ? ' this collection';
    return (
        <>
            <PopupWrapperOverlay visible={visible}>
                <PopupWrapper visible={visible}>
                    <ClosePopup closePopup={closePopup} />
                    <div>
                        Please confirm that you would like to <ValueHighlighted><Strong action={action.text.toLowerCase()}>{action.text}</Strong> this collection</ValueHighlighted>
                    </div>
                    <Subtext><ValueHighlighted>{value}</ValueHighlighted></Subtext>
                    <ButtonContainer>
                        <Button
                            text={action.text}
                            width="100%"
                            onClick={() => {
                                handleAssignment()
                                closePopup()
                            }}
                            background="transparent" />
                    </ButtonContainer>
                </PopupWrapper>
            </PopupWrapperOverlay>
        </>

    )
}



const Action = ({ content, status, setAssignment, toScheduler }) => {
    console.log("status is", status)
    switch (content.text) {
        case 'PENDING':
            return (<AcceptRejectWrapper>
                <AcceptButton onClick={setAssignment(ACCEPT)}></AcceptButton>
                <RejectButton onClick={setAssignment(REJECT)}></RejectButton>
            </AcceptRejectWrapper>)
        case 'UNSCHEDULED':
            return (
                <ScheduleButton>
                    <Button
                        text={"Schedule Collection"}
                        fontSize="10px"
                        width="100%"
                        onClick={() => {
                            toScheduler()
                        }}
                        background="transparent" />
                </ScheduleButton>)
        case RESCHEDULE_REQUIRED:
            return (
                <ScheduleButton>
                    <Button
                        text={"Schedule Collection"}
                        fontSize="10px"
                        width="100%"
                        onClick={() => {
                            toScheduler()
                        }}
                        background="transparent" />
                </ScheduleButton>)
        default:
            return <></>
    }
}

const Details = ({ item, content, filter, setBreadcrumb }) => {
    console.log("content type is: ", filter, NEW_COLLECTIONS)
    const header = filter === NEW_ARTISTS ? 'New Artist' : filter === NEW_COLLECTIONS ? 'Collection' : '';

    return (
        <>
            <DetailsWrapper>
                <Title>{item.name}</Title>
                <Filter>Collection</Filter>
            </DetailsWrapper>
            <DetailsWrapper>
                <Title><a href={`mailto:${item.artist.email}`} style={{color: 'var(--uiollyPurple)'}}>{item.artist.email}</a></Title>
                <Filter>Email</Filter>
            </DetailsWrapper>
            <DetailsWrapper>
                <Title>{item.artist.first_name}</Title>
                <Filter>Artist</Filter>
            </DetailsWrapper>
        </>
    )



}

const ProcessPendingBar = ({
    item,
    type,
    value,
    onClic,
    filter,
    content,
    isApproved,
    setBreadcrumb,
    visible,
    toScheduler
}) => {
    const [popupVisible, setPopupVisibility] = useState(false);
    const { statusKey } = useImportCollectionStatus();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const navigate = useNavigate()
    console.log(content, "ITEM")
    const [processItem, setProcessItem] = useState({
        userID: null,
        songID: null,
        status: null,
        email: '',
        name: null,
        songTitle: null
    });
    const [action, setAction] = useState({ value: '', text: '' });
    console.log("filter", filter)
    const setAssignment = (input) => (event) => {
        setPopupVisibility(true)
        setAction(input)
        setProcessItem(item)
        console.log(item, "item")
    }
    const handleAssignment = async () => {
        try {
            setError(false);
            setLoading(true);
            //console.log(item.collection_id, action.value)
            const pendingCollection = await API.processCollection(item.collection_id, action.value)
            navigate('/')
            //const pendingCollection = await API.processCollection(collectionID, status)

        } catch (e) {
            setError(true);
            console.log(e)
        }
        setLoading(false)
    }
    const closePopup = () => {
        setPopupVisibility(false);
        setAction({ value: '', text: '' })
    }
    if (!visible) {
        return <></>
    }
    else {
        return (
            <>
                <Wrapper isApproved={isApproved}>
                    <Details item={item} content={content} filter={filter}></Details>
                    <Action content={content} status={item.status} item={item} setAssignment={setAssignment} toScheduler={toScheduler} setBreadcrumb={setBreadcrumb} />
                </Wrapper>
                <Popup
                    handleAssignment={handleAssignment}
                    action={action}
                    filter={filter}
                    closePopup={closePopup}
                    visible={popupVisible !== undefined ? popupVisible : false}
                    type={type}
                    value={value} />
            </>
        )
    }
}

export default ProcessPendingBar
