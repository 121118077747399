import React, { useState, useEffect } from 'react'

import {
    Wrapper,
    FormWrapper,
    Content,
    LinkButton,
    SubmitButton,
    CloseButtonStyle,
    QuestionWrapper,
    Error,
    NavLink,
    SwitchField
} from './LoginOverlay.styles'
import InputField from '../InputField'
// import Button from '../Button'

import { validateEmail } from '../../helpers.js'

import API from '../../API'

import { useNavigate, useLocation } from 'react-router-dom'

import { useAuth } from '../../contexts/AuthContext';

import {
    LOGIN_POPUP,
    SIGNUP_POPUP,
    SET_PASSWORD_POPUP
} from '../../config.js'

const FormContent = ({ popupType, handleChange, continueKey, state, user }) => {

    if (popupType === SIGNUP_POPUP) {
        return (
            <>
                <QuestionWrapper
                //marginTop={"100px"}
                >
                    <InputField
                        type="text"
                        onChange={handleChange('name')}
                        onKeyPress={continueKey}
                        name='name'
                        placeholder='Enter your name'
                        marginBottom={'20px'}
                        value={state.name}
                    />
                </QuestionWrapper>
                <QuestionWrapper>
                    <InputField
                        type="email"
                        onChange={handleChange('email')}
                        onKeyPress={continueKey}
                        name='email'
                        placeholder='Email Address'
                        marginBottom={'20px'}
                        value={state.email}
                    />
                </QuestionWrapper>

            </>

        )
    }
    else if (popupType === LOGIN_POPUP) {
        return (
            <>
                <QuestionWrapper >
                    <InputField
                        type="email"
                        onChange={handleChange('email')}
                        name='email'
                        placeholder='Email Address'
                        marginBottom={'20px'}
                        value={state.email}
                    />
                </QuestionWrapper>
                <QuestionWrapper>
                    <InputField
                        type="password"
                        onChange={handleChange('password')}
                        name='password'
                        placeholder='Password'
                        marginBottom={'20px'}
                        value={state.password}
                    />
                </QuestionWrapper>
            </>
        )
    }
    else if (popupType === SET_PASSWORD_POPUP) {
        return (
            <>
                <QuestionWrapper >
                    <InputField
                        type="email"
                        onChange={handleChange('email')}
                        name='email'
                        placeholder='Email Address'
                        marginBottom={'20px'}
                        value={state.email}
                        disabled
                    />
                </QuestionWrapper>
                <QuestionWrapper>
                    <InputField
                        labelFontSize="10px"
                        labelTextTransform="uppercase"
                        subText={<>Min 8 characters<br></br>At least one letter<br />at least one number</>}
                        label="Set a password"
                        type="password"
                        onChange={handleChange('password')}
                        name='password'
                        placeholder='Password'
                        marginBottom={'20px'}
                    />
                </QuestionWrapper>
                <QuestionWrapper>
                    <InputField
                        labelFontSize="10px"
                        labelTextTransform="uppercase"
                        label="Reenter password"
                        type="password"
                        onChange={handleChange('passwordV')}
                        name='passwordV'
                        placeholder='Confirm Password'
                        marginBottom={'20px'}
                    />
                </QuestionWrapper>
            </>
        )
    }
    else return <></>
}

const CloseButton = ({ setShowLogin }) => {

    const onClick = (event) => {
        event.preventDefault()
        setShowLogin(false)
    }
    return (
        <CloseButtonStyle
            onClick={onClick}
        >
            &#10006;</CloseButtonStyle>)
}

const LoginOverlay = ({ onChange, setShowLogin, popupType, setPopupType, user, setUser, onClick }) => {
    //const { login } = useAuth();
    const { signup, login, currentUser } = useAuth();
    const navigate = useNavigate()
    const location = useLocation();


    const continueKey = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            // handleKeyPress(event)
            if (validateStep()) {
                setShowLogin(false)
                navigate('/get-started', { state: { name: state.name, email: state.email } })
            }
        }
    }



    const initialState = {
        email: user !== undefined ? user.email !== undefined ? user.email : "" : "",
        password: "",
        passwordV: "",
        name: ""
    }
    const validatePassword = (pw) => {
        const re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
        return re.test(String(pw));
    }
    const [state, setState] = useState(initialState);
    const [submitAttempt, setSubmitAttempt] = useState("");
    //const [currentUser, setCurrentUser] = useState(initialState);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault()
        if (validateStep()) {
            setLoading(true)
            signup(state.email, state.password).then((userCred) => {
                setLoading(false)
                navigate('/dashboard')
                //setCurrentUser(userCred.user)
            })
                .catch((error) => {
                    const errorCode = error.code;
                    console.log("errorCode", error)
                    setLoading(false)
                })
        }
    }
    useEffect(() => {
        if (currentUser) {
            const navTo = location.state !== undefined && location.state !== null && location.state.from !== undefined ? location.state.from : '/dashboard';
            navigate(navTo)
        }
    }, [currentUser])
    const handleLogin = (e) => {
        e.preventDefault()
        if (validateStep()) {
            setLoading(true)
            setSubmitAttempt('')
            login(state.email, state.password)
                .then((userCred) => {
                    setLoading(false)
                    const settoken = API.setAccessToken(state.email, userCred.user.accessToken)
                    // setCurrentUser(userCred.user)
                    // console.log(userCred)
                    setShowLogin(false)
                    const navTo = location.state !== undefined && location.state !== null && location.state.from !== undefined ? location.state.from : '/dashboard';
                    navigate(navTo)
                })
                .catch((error) => {
                    console.log("error response: ", error.message, error.errors)
                    const errCode = error.code.includes("wrong-password") ? "Incorrect password."
                        : error.code.includes("user-not-found") ? "Account not found."
                            : error.code.includes("too-many-requests") ? "Too many attempts. Try again later or reset your password."
                                : "Unknown Error";
                    setSubmitAttempt(errCode)
                    setLoading(false)
                })
        }
    }
    const [validContinue, setValidContinue] = useState(false);
    const validateStep = () => {
        console.log("validate", state.email, state.password)
        if (popupType === LOGIN_POPUP && validateEmail(state.email) && state.password !== '') {
            setValidContinue(true)
            return true;
        }
        else if (popupType === SIGNUP_POPUP && validateEmail(state.email) && state.name !== '') {
            setValidContinue(true)
            return true;
        }
        else if (popupType === SET_PASSWORD_POPUP && validateEmail(state.email) && validatePassword(state.password) && state.password === state.passwordV) {
            setValidContinue(true)
            return true;
        }
        else {
            setValidContinue(false)
            return false
        }
    }
    const handleChange = (input) => (event) => {
        setState(prev => ({
            ...prev,
            [input]: event.target.value
        }))
        if (popupType === SET_PASSWORD_POPUP && setUser !== undefined) {
            setUser(prev => ({ ...prev, password: event.target.value }))
        }
    }
    const onClickButton = (event) => {
        if (validContinue) {
            setShowLogin(false)
        }
        else {
            console.log("false")
        }
    }
    useEffect(() => {
        validateStep()
    }, [state])

    console.log(currentUser, "current user")

    return (
        <Wrapper>
            <Content>
                <h1 style={{ textAlign: 'center' }}>Uiolly</h1>
                <h5 style={{ textAlign: 'center' }}>Admin Portal</h5>
                <FormWrapper>
                    <FormContent
                        user={user}
                        popupType={popupType}
                        handleChange={handleChange}
                        continueKey={continueKey}
                        state={state} />

                    <QuestionWrapper>
                        {popupType === SIGNUP_POPUP &&
                            <LinkButton
                                color={validContinue ? '#000000' : '#ffffff'}
                                width="100%"
                                background={validContinue ? '#ffffff' : "rgba(255,255,255,0.15)"}
                                fontWeight={validContinue ? '700' : '700'}
                                border={validContinue ? "2px solid #ffffff" : '2px solid rgba(255,255,255,0.1)'}
                                onClick={onClickButton}
                                state={{ name: state.name, email: state.email }}
                                to={popupType === SIGNUP_POPUP ? "/get-started" : "/"}
                                cursor={validContinue ? 'pointer' : 'default'}
                            >{popupType}
                            </LinkButton>
                        }
                        {popupType === SET_PASSWORD_POPUP &&
                            <SubmitButton
                                color={validContinue ? '#000000' : '#ffffff'}
                                width="100%"
                                background={validContinue ? '#ffffff' : "rgba(255,255,255,0.15)"}
                                fontWeight={validContinue ? '700' : '700'}
                                border={validContinue ? "2px solid #ffffff" : '2px solid rgba(255,255,255,0.1)'}
                                // onClick={onClick}
                                onClick={handleSubmit}
                                disabled={loading}
                                cursor={validContinue ? 'pointer' : 'default'}
                            >{popupType}
                            </SubmitButton>
                        }
                        {popupType === LOGIN_POPUP &&
                            <SubmitButton
                                color={validContinue ? '#000000' : 'var(--lightGrey)'}
                                width="100%"
                                background={validContinue ? '#ffffff' : "var(--lightestGrey)"}
                                fontWeight={validContinue ? '700' : '700'}
                                onClick={handleLogin}
                                border={validContinue ? "2px solid var(--fontColor)" : '2px solid var(--lightGrey)'}
                                //onClick={() => login(state.email, state.password).then(() => { setShowLogin(false); navigate("/dashboard"); })}
                                cursor={validContinue ? 'pointer' : 'not-allowed'}
                            >{popupType}
                            </SubmitButton>
                        }
                    </QuestionWrapper>
                    {submitAttempt !== "" &&
                        <QuestionWrapper marginTop="5px">
                            <Error>{submitAttempt}</Error>
                        </QuestionWrapper>}
                </FormWrapper>
            </Content>
        </Wrapper>
    )

}

export default LoginOverlay
