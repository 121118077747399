import styled from "styled-components";

export const ButtonStyle = styled.button`
    height: ${props => props.height ? props.height : 'var(--buttonHeight)'};
    font-size: ${props => props.fontSize ? props.fontSize : 'var(--buttonFontSize)'};
    color: ${props => props.color ? props.color : props.validContinue ? 'var(--fontColor)' : 'var(--buttonDisabledText)'};
    width: ${ ({width}) => width};
    background: ${props => props.background};
    text-transform: uppercase;
    cursor: ${props => props.validContinue ? 'pointer' : 'not-allowed'};
    border: ${ ({border}) => border};
    border: ${ props =>  props.border ? props.border : props.validContinue ? 'var(--buttonBorder)': 'var(--buttonDisabledBorder)'};
    background: ${props => props.validContinue ? props.background : 'var(--buttonDisabledBgd)'};
    font-weight: ${ props => props.validContinue ? '500' : '700'};
    letter-spacing: 0.08rem;
    
    :hover{
        background: ${props => props.validContinue ? 'rgba(255,255,255,0.15)' : props.hoverColor  ? props.hoverColor : '' } ;

    }
`;