import React, { useEffect, useRef, useState } from 'react'

import { Wrapper, InputStyled, LabelStyled, DollarSign, InnerWrapper } from './InputField.styles'


const Label = ({ name, marginBottom, label }) => (


    <LabelStyled htmlFor={name}
        marginBottom={marginBottom}
    >{label}</LabelStyled>
)




const InputField = ({ priceOnBlur, type, image, onChange, placeholder, value, label, name, marginBottom, onKeyPress }) => {
    const initial = useRef(true);
    const inputRef = useRef(null);
    const [cursorPosition, setCursorPosition] = useState(0);
    const [initialLength, setInitialLength] = useState(0);

    


    const handleCursor = (event) => {

        event.preventDefault();

        setCursorPosition(inputRef.current.selectionStart)
        setInitialLength(inputRef.current.value.length)
        onChange(event);
    }

    useEffect(() => {
        if (initial.current) {
            // note we can mutate this directly instead of using a "setStateXyz"
            // function because it's a useRef
            initial.current = false;
            return; // if initial render, skip useEffect
        }
        if (name === 'price') {
            let length = inputRef.current.value.length
            let caretPos = length - initialLength + cursorPosition;
            console.log(length, initialLength, cursorPosition);
            inputRef.current.setSelectionRange(caretPos, caretPos);



        }
    }, [value])



    if (label !== undefined) {

        return (
            <Wrapper >
                
                <Label htmlFor={name}
                    marginBottom={marginBottom}
                    label={label}
                ></Label>
                <InnerWrapper>
                {name === 'price' && <DollarSign>$</DollarSign>}
                    <InputStyled
                        type={type}
                        ref={inputRef}
                        placeholder={placeholder}
                        value={value}
                        className={`cp`}
                        onChange={handleCursor}
                        name={name}
                        onKeyPress={onKeyPress}
                        onBlur={priceOnBlur !== undefined ? priceOnBlur : undefined}


                    />
                </InnerWrapper>
            </Wrapper>

        )
    }
    else {
        return (
            <InputStyled
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                ref={inputRef}



            />

        )
    }



}

export default InputField
